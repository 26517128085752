<script setup>
import {computed, defineProps, ref} from 'vue';
import ListaLeiloes from '../Componentes/Home/ListaLeiloes';
import MainLayout from '../Layouts/MainLayout';
import ListaLotesSemLeilao from '../Componentes/Home/ListaLotesSemLeilao';
import useGlobals from '../Composables/useGlobals';
import {usePage} from "@inertiajs/inertia-vue3";

const props = defineProps({
    leiloes: Array,
    lotes: Array,
    termo: String
});

const {
    nomeLeiloeiro
} = useGlobals();

const tab = ref(props.lotes.length > 0 ? 'lotes' : 'leiloes');

if (window.location.hash === '#leiloes' && props.leiloes.length > 0) {
    tab.value = 'leiloes';
} else if (window.location.hash === '#lotes' && props.lotes.length > 0){
    tab.value = 'lotes';
}

const dominio_venda_direta_ativo = computed(()=> usePage().props.value.dominio_venda_direta_ativo);

</script>

<template>
    <MainLayout :title="'Resultados para ' + termo" :description="'Aqui na ' + nomeLeiloeiro + ' você encontra os melhores resultados para ' + termo">
        <q-page>
            <div
                class="flex column justify-start content-stretch items-center">
                <h1 class="text-center text-h4 q-mb-none">
                    Resultados para "{{termo}}"
                </h1>
                <p  class="text-center text-subtitle1">
                    Aqui está o que encontramos
                </p>
            </div>
            <div class="flex column justify-start content-stretch full-width">
                <q-tabs
                    v-model="tab"
                    dense
                    class="text-grey"
                    active-color="primary"
                    indicator-color="primary"
                    align="justify"
                >
                    <q-tab name="lotes" :disable="!(lotes.length > 0)" label="Lotes" />
                    <q-tab name="leiloes" :disable="!(leiloes.length > 0)" :label="(dominio_venda_direta_ativo) ? 'Eventos' : 'Leilões'" />
                </q-tabs>
                <q-tab-panels v-model="tab" animated>
                    <q-tab-panel name="leiloes" class="q-px-none">
                        <div v-if="leiloes.length > 0" class="flex column col-12">
                            <p  class="text-center text-primary text-bold text-h5">
                                Leilões Encontrados
                            </p>
                            <div class="flex row full-width">
                                <lista-leiloes :leiloes="leiloes" />
                            </div>
                        </div>
                        <div class="flex column col-12" v-else>
                            <p  class="text-center text-primary text-bold text-h5">
                                Nenhum {{(dominio_venda_direta_ativo) ? 'Evento' : 'Leilão'}} Encontrado
                            </p>
                        </div>
                    </q-tab-panel>

                    <q-tab-panel name="lotes" class="q-px-none">
                        <div class="flex column col-12" v-if="lotes.length > 0">
                            <p  class="text-center text-primary text-bold text-h5">
                                Lotes Encontrados
                            </p>
                            <div class="flex row full-width">
                                <lista-lotes-sem-leilao :lotes="lotes" />
                            </div>
                        </div>
                        <div class="flex column col-12" v-else>
                            <p  class="text-center text-primary text-bold text-h5">
                                Nenhum Lote Encontrado
                            </p>
                        </div>
                    </q-tab-panel>
                </q-tab-panels>
            </div>
        </q-page>
    </MainLayout>
</template>
