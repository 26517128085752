<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import {defineProps, onBeforeUnmount, onMounted} from 'vue';
import useAuditorio from '../Composables/useAuditorio';
import { useQuasar } from 'quasar';
import CardLancesTelao from "../Componentes/Auditorio/CardLancesTelao";
import CardDetalhesTelao from "../Componentes/Auditorio/CardDetalhesTelao";
import BtnLote from "../Componentes/Auditorio/BtnLote";
import SlimNoFooterNoHeaderLayout from "../Layouts/SlimNoFooterNoHeaderLayout";
import useLeilaoLocalComUpdates from "@/Composables/useLeilaoLocalComUpdates";
import useUtils from "@/Composables/useUtils";

const props = defineProps({
    leilao: Object,
    loteSelecionadoInit: Object,
    lotesInit: Array,
    habilitado: Boolean
})

const {
    leilao
} = useLeilaoLocalComUpdates({
    leilaoInit: props.leilao
})

const {
    formatador,
    formatadorLote
} = useUtils();

const $q = useQuasar();

const {
    mostrarImagens,
    seguirLeiloeiro,
    lotes,
    abrirFixarEvent,
    loteLeiloeiro,
    lote
} = useAuditorio(
    props.leilao,
    props.loteSelecionadoInit,
    props.lotesInit
)

let leiloeiroAndou = (e) => {
    loteLeiloeiro.value = e.id;
    if (seguirLeiloeiro.value) {
        $q.loading.show({
            message: '<p>Seguindo leiloeiro...<br/> Para desativar este comportamento, clique no seletor no canto superior direito da tela</p>',
            html: true
        });
        lote.value = e.dados;
        setTimeout(() => {
            $q.loading.hide();
            document.getElementsByClassName('atual')[0].scrollIntoView()
        }, 800)

    }
}

window.Echo.channel('auditorio.' + props.leilao.id)
.listen('.leiloeiroAndou', (e) => {
    leiloeiroAndou({ id: e.novoLote, dados: e.dadosNovoLote });
});

onMounted(() => {
    document.body.style.overflowY = 'hidden';
})

onBeforeUnmount(() =>{
    leiloeiroAndou = () => {};
    document.body.style.overflowY = 'auto';
})


</script>
<template>
    <Head :title="'Telão - ' + lote.titulo"/>
    <SlimNoFooterNoHeaderLayout>
        <q-page class="flex column">
            <div class="full-width flex row justify-between items-center">
                <div class="flex column q-px-md q-mt-lg items-start">
                    <h4 class="q-ma-none text-primary">
                        LOTE {{formatadorLote(lote.sequencia)}} - {{ lote.titulo }}
                    </h4>
                </div>
                <div class="full-height q-mr-md flex column justify-center">
                    <q-toggle
                        :true-value="true"
                        :false-value="false"
                        v-model="seguirLeiloeiro"
                        label="Seguir Moderador"/>
                </div>
            </div>

            <div class="full-width flex row no-wrap q-pt-md q-px-xs" style="flex: 1">
                <div class="flex column col-8 q-pa-sm">
                    <card-detalhes-telao :key="lote.id" :lote-init="lote" :leilao-init="leilao"
                                             :habilitado="habilitado">
                        <template v-slot:botoes-lote>
                            <btn-lote @selecionado="abrirFixarEvent"
                                      :lote-leiloeiro="loteLeiloeiro"
                                      v-for="loteCard in lotes"
                                      :key="loteCard.id"
                                      :lote-init="loteCard"
                                      :leilao="leilao"
                                      :mostrar-imagens="mostrarImagens"
                                      :lote-selecionado="lote"
                            />
                        </template>
                    </card-detalhes-telao>
                </div>

                <div class="flex column col-4 full-height" >

                    <div class="column full-width"
                         scroll-region>
                        <div class="flex column full-width q-pa-sm">
                            <card-lances-telao :key="lote.id" :lote-init="lote" :leilao-init="leilao"
                                         :habilitado="habilitado" />
                        </div>
                    </div>


                        <div class="q-px-xs col-6 column">
                            <q-card class="flex column no-wrap full-width shadow-0">
                                <q-toolbar class="bg-primary text-white">
                                    <q-icon name="mdi-gavel" />
                                    <q-toolbar-title>
                                        Informações do Leiloeiro
                                    </q-toolbar-title>
                                </q-toolbar>
                                <div style="height: 30vh; overflow-y: auto;" class="q-pa-sm q-pt-lg">
                                    <p v-if="!(lote.lanceAtual > 0)" class="text-subtitle2 q-mb-none text-center">
                                        Lance Inicial
                                    </p>
                                    <p v-if="!(lote.lanceAtual > 0)" class="text-h4 q-mb-sm text-center text-primary">
                                        {{ formatador.format(lote.lance_inicial) }}
                                    </p>
                                    <p v-if="lote.lanceAtual > 0" class="text-subtitle2 q-mb-none text-center">
                                        Lance Atual
                                    </p>
                                    <p v-if="lote.lanceAtual > 0" class="text-h4 q-mb-sm text-center text-primary">
                                        {{ formatador.format(lote.lanceAtual) }}
                                    </p>
                                    <p class="text-subtitle1 q-mb-none text-center">
                                        Próximo Lance
                                    </p>
                                    <p class="text-h3 q-mb-sm text-center text-primary">
                                        <b>{{ formatador.format(lote.proximoLance) }}</b>
                                    </p>
                                    <p class="text-subtitle2 q-mb-none text-center">
                                        Incremento
                                    </p>
                                    <p class="text-h4 q-mb-sm text-center text-primary">
                                        {{ formatador.format(lote.incrementoLote) }}
                                    </p>
                                </div>
                            </q-card>
                        </div>

                </div>
            </div>
        </q-page>
    </SlimNoFooterNoHeaderLayout>
</template>
