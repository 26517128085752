<script setup>
import { Head, useForm } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../Layouts/BlankLayout';
import Icone from '../../Componentes/Global/Icone';
import useRotas from '../../Composables/useRotas';

const route = useRotas();

const form = useForm({
    email: null,
})

</script>

<template>
    <Head title="Redefinição de Senha" />
    <BlankLayout>
        <q-page class="flex row justify-center items-center">
            <div class="flex column items-center col-11 col-md-5" >
                <icone height="7em" width="14em" />
                <h6 class="text-center text-primary font-weight-light q-my-md">
                    Solicitar Redefinição de senha
                </h6>
                <q-form class="full-width" @submit="form.post(route('auth.login.redefinirSenha.enviar'))">
                    <q-input
                        v-model="form.email"
                        :rules="[ val => val && val.length > 0 || 'Por favor digite seu email']"
                        class="full-width"
                        color="primary"
                        dense
                        label="Digite Seu email"
                        label-color="primary"
                        lazy-rules
                        type="email"
                    />
                    <q-btn
                        class="full-width"
                        color="primary"
                        label="Solicitar Redefinição de Senha"
                        type="submit"
                        :loading="form.processing"
                    />
                    <q-btn
                        class="full-width q-mt-sm"
                        color="primary"
                        flat
                        label="Voltar"
                        type="button"
                        @click="$inertia.visit(route('auth.login'))"
                    />
                </q-form>
            </div>
        </q-page>
    </BlankLayout>
</template>
