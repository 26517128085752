<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../Layouts/BlankLayout';
import Icone from '../../Componentes/Global/Icone';
import useRotas from '../../Composables/useRotas';
import { useQuasar } from 'quasar';

const $q = useQuasar();
const route = useRotas();
</script>

<template>
    <Head title="Cadastro" />
    <BlankLayout>
        <q-page class="flex row justify-center items-center">
            <div class="flex column items-center col-11">
                <icone height="7em" width="14em" class="q-mb-md" />
                <h4
                    class="text-center text-primary font-weight-light q-ma-md q-mb-lg"
                    :class="$q.screen.gt.sm ? 'text-h4' : 'text-h5'">
                    Como você deseja se cadastrar?
                </h4>
                <q-btn
                    icon="mdi-account" label="Pessoa física" stack glossy
                    color="primary" style="width: 14em;"
                    @click="$inertia.visit(route('cadastro.fisica'))"
                />
                <q-btn
                    icon="mdi-office-building-outline" label="Pessoa jurídica" stack glossy
                    color="primary" style="width: 14em;"
                    class="q-mt-md"
                    @click="$inertia.visit(route('cadastro.juridica'))"
                />
            </div>
        </q-page>
    </BlankLayout>
</template>
