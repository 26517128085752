<script setup>
import { computed,ref } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';

const logado = computed(() => usePage().props.value.auth?.autenticado);
const cliente = computed(() => usePage().props.value.auth?.cliente)
const mostrarPopup = ref(false);
const togglePopup = () => {
    mostrarPopup.value = !mostrarPopup.value;
}
</script>

<template>
    <q-page-sticky
        v-if="logado && cliente?.status_cliente?.identificador === 'AGUARDANDO_ANALISE'"
        position="bottom" :offset="[10, 10]">
        <q-banner inline-actions rounded class="bg-orange text-white" style="z-index: 1000000">
            A sua conta está em análise.
            <template v-slot:action>
                <q-btn flat color="white" label="Saiba Mais" @click="togglePopup" />
            </template>
        </q-banner>
    </q-page-sticky>
    <q-dialog v-if="logado && cliente?.status_cliente?.identificador === 'AGUARDANDO_ANALISE'" :model-value="mostrarPopup">
        <q-card>
            <q-card-section>
                <h6 class="no-margin">
                    O seu cadastro está sendo analisado
                </h6>
            </q-card-section>
            <q-card-section>
                <p class="no-margin">
                    A nossa equipe está revisando as informações fornecidas no seu cadastro, e logo você poderá dar lances.
                </p>
            </q-card-section>
            <q-card-actions align="right">
                <q-btn flat label="OK" color="positive" @click="togglePopup"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
