<script setup>
import { defineProps, ref } from 'vue';
import useUtils from '../../Composables/useUtils';
import Lance from '../Leilao/Lance';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import useLeilaoLocalComUpdates from '../../Composables/useLeilaoLocalComUpdates';
import useLote from '../../Composables/useLote';
import useGlobals from '../../Composables/useGlobals';
import {useQuasar} from "quasar";

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    habilitado: Boolean,
});

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    leilao
} = useLeilaoLocalComUpdates(props)

const {
    getImagem,
    formatDate,
    formatador,
    formatadorLote
} = useUtils();

const {
    autoplay,
    slide,
    timer,
    timerModerador,
} = useLote(leilao, lote)

const {
    logoLeiloeiro,
} = useGlobals();

const lanceLocal = ref(false);
const $q = useQuasar();
const modalDescrição = () => {
    $q.dialog({
        title: `Descrição Completa do lote ${lote.value.sequencia}`,
        html: true,
        fullWidth: true,
        message: lote.value.descricao
    })
}
</script>

<template>
    <q-card class="full-width full-height">
        <q-card-section
            style="display: flex; flex-direction: row; justify-content: stretch; align-items: stretch;padding: 0"
            class="text-subtitle1 text-white text-center column justify-center">
            <div
                style="height: 50px;width: 50%;transition: all .75s ease-in-out"
                :style="`background-color: ${leilao.status_leilao?.cor_site};`"
                class="text-h5 text-white text-center column justify-center">
                Leilão: {{ leilao.status_leilao?.nome }}
            </div>
            <div
                style="height: 50px;width: 50%;transition: all .75s ease-in-out"
                :style="`background-color: ${lote.status_lote?.cor_site};`"
                class="text-h5 text-white text-center column justify-center">
                Lote: {{ lote.status_lote?.nome }}
            </div>
        </q-card-section>
        <q-card-section class="q-pa-none">
            <q-card-section class="q-pa-none q-mb-xs flex row">
                <q-carousel
                    class="col-6"
                    v-model="slide"
                    :autoplay="autoplay"
                    animated
                    arrows
                    style="height: 35vh;"
                    control-color="white"
                    control-text-color="primary"
                    control-type="push"
                    next-icon="mdi-chevron-right"
                    prev-icon="mdi-chevron-left"
                    infinite
                    swipeable
                    @mouseenter="autoplay = false"
                    @mouseleave="autoplay = true"
                >
                    <q-carousel-slide
                        v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0  ? lote.imagens_lote : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))"
                        :key="key" :name="1+key"
                        style="background-position: center;background-size: cover;background-repeat: no-repeat"
                        class="q-pa-none">
                        <q-img
                            class="full-width full-height"
                            loading="lazy"
                            :src="getImagem(imagem?.arquivo, true, lote.status_lote?.identificador)"
                            :ratio="16/9"
                        >
                            <template v-slot:error>
                                <div class="absolute-full flex flex-center bg-primary text-white">
                                    Houve um problema carregando a imagem
                                </div>
                            </template>
                            <template v-slot:loading>
                                <div class="absolute-full flex column flex-center bg-white text-primary">
                                    <img :src="logoLeiloeiro" style="width: 40%" />
                                    <p class="q-mt-sm">
                                        <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                    </p>
                                </div>
                            </template>
                        </q-img>
                    </q-carousel-slide>
                </q-carousel>
                <div class="col-6">
                    <div class="full-width q-px-md q-pt-md">
                        <h6 class="q-ma-none text-primary">
                            {{formatadorLote(lote.sequencia)}} - {{ lote.titulo }}
                        </h6>
                    </div>
                    <q-list class="full-width">
                        <q-item clickable @click="modalDescrição">
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-label-multiple-outline"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label style="max-height: 7vh;overflow-y: auto" class="nopad" caption v-html="lote.descricao">
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 1 && lote.proximoStatus.timestamp && +leilao.tipo_fechamento === 1" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{timer}}</q-item-label>
                                <q-item-label caption>{{lote.proximoStatus.texto}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 0 && lote.timer_moderador && timerModerador" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{timerModerador}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 1 && (lote.proximoStatusTime) && (leilao.tipo_fechamento != '0' || lote.status_lote.identificador == 'EM PREGAO')" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{lote.proximoStatusInterval}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-calendar"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label
                                    :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through'  : ''"
                                    :caption="(+leilao.primeiro_leilao_concluido === 1 && +leilao.dois_leiloes === 1) ? true : false"
                                >
                                    1º Leilão: {{ formatDate(leilao.data_hora_inicio) }}
                                </q-item-label>
                                <q-item-label
                                    :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through'  : ''"
                                    :caption="(+leilao.primeiro_leilao_concluido === 1) ? false : true"
                                    v-if="leilao.dois_leiloes === '1'"
                                >
                                    2º Leilão: {{ formatDate(leilao.data_hora_inicio_segundo_leilao) }}
                                </q-item-label>
                                <q-item-label v-else caption>
                                    Sem Segundo Leilão
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="positive" name="mdi-cash"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label v-if="!(lote.lanceAtual > 0)">Lance Inicial {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}</q-item-label>
                                <q-item-label v-if="lote.lanceAtual > 0">Lance Atual {{ formatador.format(lote.lanceAtual) }}</q-item-label>
                                <q-item-label caption v-if="lote.lanceAtual > 0">Lance Inicial {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1 && +leilao.dois_leiloes === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="positive" name="mdi-cash-fast"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label>Próximo Lance {{ formatador.format(lote.proximoLance) }}</q-item-label>
                                <q-item-label caption>Incremento {{ formatador.format(lote.incrementoLote) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
            </q-card-section>
            <q-card-section class="q-pa-none" style="flex: 4">
                <div class="full-width flex row">
                    <div class="q-px-xs col-6 column">
                        <q-card class="flex column no-wrap full-width shadow-0">
                            <q-toolbar class="bg-primary text-white">
                                <q-icon name="mdi-package-variant-closed" />
                                <q-toolbar-title>
                                    Lotes
                                </q-toolbar-title>
                            </q-toolbar>
                            <div
                                style="max-height:32vh;overflow-y: auto;"
                                class="q-pa-sm flex row wrap justify-center align-items-start">
                                <slot name="botoes-lote"></slot>
                            </div>
                        </q-card>
                    </div>
                    <div class="q-px-xs col-6 column">
                        <q-card class="flex column no-wrap full-width shadow-0">
                            <q-toolbar class="bg-primary text-white">
                                <q-icon name="mdi-gavel" />
                                <q-toolbar-title>
                                    Informações do Leiloeiro
                                </q-toolbar-title>
                            </q-toolbar>
                            <div style="height: 30vh; overflow-y: auto;" class="q-pa-sm q-pt-lg">
                                <p v-if="!(lote.lanceAtual > 0)" class="text-subtitle2 q-mb-none text-center">
                                    Lance Inicial
                                </p>
                                <p v-if="!(lote.lanceAtual > 0)" class="text-h4 q-mb-sm text-center text-primary">
                                    {{ formatador.format(lote.lance_inicial) }}
                                </p>
                                <p v-if="lote.lanceAtual > 0" class="text-subtitle2 q-mb-none text-center">
                                    Lance Atual
                                </p>
                                <p v-if="lote.lanceAtual > 0" class="text-h4 q-mb-sm text-center text-primary">
                                    {{ formatador.format(lote.lanceAtual) }}
                                </p>
                                <p class="text-subtitle2 q-mb-none text-center">
                                    Próximo Lance
                                </p>
                                <p class="text-h3 q-mb-sm text-center text-primary">
                                    {{ formatador.format(lote.proximoLance) }}
                                </p>
                                <p class="text-subtitle2 q-mb-none text-center">
                                    Incremento
                                </p>
                                <p class="text-h4 q-mb-sm text-center text-primary">
                                    {{ formatador.format(lote.incrementoLote) }}
                                </p>
                            </div>
                        </q-card>
                    </div>
                </div>
            </q-card-section>
            </q-card-section>
        <Lance
            v-model="lanceLocal"
            :leilao="lote.leilao"
            :lote="lote"
            :habilitado="habilitado"
        />
    </q-card>
</template>
<style>
.nopad > p{
    margin: 0;
}
</style>
