<script setup>
import { defineProps, ref } from 'vue';
import useUtils from '../../Composables/useUtils';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import {useQuasar} from "quasar";
import useRotas from "@/Composables/useRotas";
import {useForm} from "@inertiajs/inertia-vue3";

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    habilitado: Boolean,
});

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    formatDate,
    formatador,
} = useUtils();

const confirmExclusaoLance = ref(false);

const route = useRotas();

const $q = useQuasar();

const form = useForm({
    idLance: null
});

const mostrarModalRemoverLance = (id) => {
    confirmExclusaoLance.value = true;
    form.idLance = id;
};

const resetaForm = () => {
    form.idLance = null;
};



const removerLance = async () => {

    if(form.idLance) {
        $q.loading.show({
            message: 'Excluindo Lance...'
        })
        fetch(
            route('moderador.mod.lance.removerLance', {idLeilao: lote.value.id_leilao, idLote: lote.value.id}),
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify(form.data())
            });
        confirmExclusaoLance.value = false;

        $q.loading.hide();
    }

    form.idLance = null;
};

</script>

<template>
    <q-card class="flex column no-wrap full-width shadow-0">
        <q-toolbar class="bg-primary text-white">
            <q-icon name="mdi-gavel" />
            <q-toolbar-title>
                Lances ({{lote?.lances?.length || '0'}} lances registrados)
            </q-toolbar-title>
        </q-toolbar>
        <q-list style="height: 40vh; overflow-y: auto;">
            <q-item v-for="(lance, idx) in lote.lances" :key="idx" clickable>

                <q-item-section avatar class="items-center">
                    <q-icon :color="(idx === 0) ? 'positive' : 'primary'" size="lg" name="mdi-cash"/>
                </q-item-section>

                <q-item-section>
                    <q-item-label v-if="idx === 0" overline>Lance Atual</q-item-label>
                    <q-item-label v-else overline>Lance</q-item-label>
                    <q-item-label  :class="(idx === 0) ? 'text-positive text-h5' : 'text-primary text-subtitle2'">
                        <b>{{ formatador.format(lance.valor) }}</b> por <b>{{lance.cliente.usuario.username}}</b><b style="font-size: 14px"> em {{formatDate(lance.data_hora)}}</b>
                        <br/>
                        <template v-if="lance?.cliente?.enderecos_cliente?.length > 0">
                            {{lance?.cliente?.enderecos_cliente[0]?.rua?.bairro?.cidade?.nome || lance?.cliente?.enderecos_cliente[0]?.cidade_endereco }}
                            / {{lance?.cliente?.enderecos_cliente[0]?.rua?.bairro?.cidade?.estado?.codigo_uf || lance?.cliente?.enderecos_cliente[0]?.uf_endereco}}
                        </template>
                    </q-item-label>
                </q-item-section>
                <q-item-section avatar class="items-center"
                                @click="mostrarModalRemoverLance(lance.id)"
                                clickable>
                    <q-icon color="primary" size="sm" name="mdi-delete"  class="lixeira"/>
                </q-item-section>
            </q-item>
        </q-list>

        <q-dialog v-model="confirmExclusaoLance" persistent>
            <q-card>

                <q-item>
                    <q-item-section avatar>
                        <q-avatar icon="mdi-trash-can" color="red" text-color="white" class="q-mb-sm"/>
                    </q-item-section>

                    <q-item-section>
                        <q-item-label class="text-h6">Excluir Lance</q-item-label>
                    </q-item-section>
                </q-item>

                <q-card-section class="row items-center">
                    <span class="q-ml-sm">Ao clicar em confirmar este lance será excluído permanentemente, não sendo posível sua recuperação. </span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Cancelar" color="primary" v-close-popup @click="resetaForm" />
                    <q-btn flat label="Confirmar" color="red" @click="removerLance" />
                </q-card-actions>
            </q-card>
        </q-dialog>


    </q-card>
</template>
<style scoped>

.lixeira:hover{
    color: red !important;
}

.nopad > p{
    margin: 0;
}
</style>
