<script setup>
import { defineProps } from 'vue';
import { Head } from '@inertiajs/inertia-vue3';
import HeaderSlim from '../Componentes/Cabecalho/Slim';
import { useDialogos } from '../Composables/useDialogos';
import AlertaAnalise from '../Componentes/AlertaAnalise';
import ReportaErros from '../Componentes/ReportaErros';
import useGlobals from '../Composables/useGlobals';

const {
    nomeLeiloeiro
} = useGlobals();

useDialogos();

defineProps({
    title: String,
    description: String,
});

</script>

<template>
    <Head>
        <title>{{(title ? (title + ' - ' ) : '') + nomeLeiloeiro}}</title>
        <meta name="description" :content="description">
    </Head>
    <q-layout view="hHh lpR fFf">
        <reporta-erros>
            <HeaderSlim class="hide-print" />
        </reporta-erros>
        <q-page-container>
            <reporta-erros>
                <slot></slot>
                <alerta-analise />
            </reporta-erros>
        </q-page-container>
    </q-layout>
</template>
