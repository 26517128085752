<script setup>
import { ref, defineProps } from 'vue';

const autoplay = ref(true);

const props = defineProps({
    banners: Array
});

const abrirUrl = (url) => {
    window.open(url, '_blank');
}

const slide = ref(props.banners[0]?.id);
</script>

<template>
    <q-responsive :ratio="90 / 90">
        <q-carousel
            v-model="slide"
            :autoplay="autoplay"
            height="20em"
            animated
            arrows
            infinite
            navigation
            swipeable
            navigation-active-icon="mdi-checkbox-blank-circle"
            navigation-icon="mdi-checkbox-blank-circle-outline"
            next-icon="mdi-chevron-right"
            prev-icon="mdi-chevron-left"
        >
            <q-carousel-slide
                              :class="banner.link ? 'cursor-pointer' : ''"
                              v-for="banner in banners"
                              :key="banner.id"
                              :name="banner.id"
                              @click="abrirUrl(banner.link)"

                              class="q-pa-none"
            >
                <q-img
                    class="full-width full-height"
                    :src="banner.arquivo_mobile.bannerMobileUrl"
                    :ratio="90/90"

                >
                    <template v-slot:error>
                        <div class="absolute-full flex flex-center bg-primary text-white">
                            Houve um problema carregando a imagem
                        </div>
                    </template>
                    <template v-slot:loading>
                        <div
                            class="absolute-full flex column bg-primary flex-center text-white"
                        >
                        </div>
                    </template>
                </q-img>
            </q-carousel-slide>
        </q-carousel>
    </q-responsive>
</template>
