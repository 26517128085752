<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../../Layouts/BlankLayout';
import Icone from '../../../Componentes/Global/Icone';
import { useQuasar } from 'quasar';

const $q = useQuasar();
</script>

<template>
    <Head title="Verificação Invalida" />
    <BlankLayout>
        <q-page class="flex column justify-center items-center content-center">
            <icone height="7em" width="14em" class="q-mb-md" />
            <h3
                class="text-center text-primary font-weight-light no-margin q-ma-md"
                :class="$q.screen.gt.sm ? 'text-h3' : 'text-h5'">
                Link de Outro Usuário
            </h3>
            <p style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                O link de confirmação usado está associado a outra conta de usuário, e não a que você está logado.
            </p>
        </q-page>
    </BlankLayout>
</template>
