<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../Layouts/BlankLayout';
import Icone from '../../Componentes/Global/Icone';
import useRotas from '../../Composables/useRotas';
import { useQuasar } from 'quasar';

const route = useRotas();
const $q = useQuasar();
</script>

<template>
    <Head title="Conta Criada" />
    <BlankLayout>
        <q-page class="flex column justify-center items-center content-center">
            <icone height="7em" width="14em" class="q-mb-md" />
            <h3
                class="text-center text-primary font-weight-light no-margin q-ma-md"
                :class="$q.screen.gt.sm ? 'text-h3' : 'text-h5'">
                Sua senha foi alterada!
            </h3>
            <p  style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                Bem vindo de volta!
            </p>
            <q-btn label="Continuar" color="primary" @click="$inertia.visit(route('home'))"/>
        </q-page>
    </BlankLayout>
</template>
