<script setup>
import {ref, reactive, watchEffect, computed} from 'vue';
import { Inertia } from '@inertiajs/inertia';
import { defineProps } from 'vue';
import {usePage} from "@inertiajs/inertia-vue3";

const props = defineProps({
    filtrosAtuais: Object,
    opcoes: Object
});

const dominio_venda_direta_ativo = computed(()=> usePage().props.value.dominio_venda_direta_ativo);

const tab = ref('tudo');
const filtros = reactive({
    precoDe: '',
    precoAte: '',
    localidade: '',
    veiculo: {
        placa: '',
        marca: '',
        modelo: '',
        anoDe: '',
        anoAte: '',
        tipoCombustivel: '',
        cambio: '',
    },
    imovel: {
        cidade: '',
        bairro: '',
        locacao: ''
    },
    ... props.filtrosAtuais
});

const filtrar = () =>{
    Inertia.reload({
        data: filtros,
        preserveState: false
    });
}
const limparFiltros = () => {
    filtros.precoDe= '';
    filtros.precoAte= '';
    filtros.localidade= '';
    filtros.veiculo = {
        placa: '',
        marca: '',
        modelo: '',
        anoDe: '',
        anoAte: '',
        tipoCombustivel: '',
        cambio: '',
    };
    filtros.imovel = {
        cidade: '',
            bairro: '',
            locacao: ''
    };
    Inertia.reload({
        data: filtros
    });
}

watchEffect(() => {
    filtros.veiculo.marca = +props.filtrosAtuais?.veiculo?.marca || null;
    filtros.veiculo.modelo = +props.filtrosAtuais.veiculo?.modelo || null;
    filtros.veiculo.cambio = +props.filtrosAtuais.veiculo?.cambio || null;
    filtros.veiculo.tipoCombustivel = +props.filtrosAtuais?.veiculo?.tipoCombustivel || null;
})
const atualizaFiltros = () => {
    setTimeout(() => filtrar(), 500);
}
const submitOnKey = function (event){
    if (event.code === 'Enter')
    {
        event.preventDefault();
        filtrar();
    }
}
</script>

<template>
    <div class="q-pa-md full-width" >
        <q-card class="full-width">
            <q-form @submit="filtrar">
                <q-tabs
                    v-model="tab"
                    active-color="positive" class="bg-primary text-white" dense
                    inline-label
                    style="border-radius: 5px 5px 0 0; height: 3em;"
                >
                    <q-tab icon="mdi-format-list-checks" label="Geral" name="tudo"/>
                    <q-tab icon="mdi-car" label="Veículos" name="veiculos"/>

                    <q-tab v-if="!dominio_venda_direta_ativo" icon="mdi-office-building-outline" label="Imóveis" name="imoveis"/>

                </q-tabs>
                <q-tab-panels v-model="tab">
                    <q-tab-panel class="flex row justify-center" name="tudo">
                        <div class="q-pa-sm col-12">
                            <q-input
                                v-model="filtros.precoDe"
                                bg-color="white"
                                class="full-width"
                                color="secondary"
                                dense
                                @keyup="submitOnKey"
                                mask="###.###.###.###,##"
                                reverse-fill-mask
                                unmasked-value
                                label="Preço De"
                                label-color="primary"
                                outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-currency-usd" />
                                </template>
                            </q-input>
                        </div>
                        <div class="q-pa-sm col-12">
                            <q-input
                                v-model="filtros.precoAte"
                                bg-color="white"
                                class="full-width"
                                color="secondary"
                                dense
                                @keyup="submitOnKey"
                                mask="###.###.###.###,##"
                                unmasked-value
                                reverse-fill-mask
                                label="Preço Até"
                                label-color="primary"
                                outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-currency-usd" />
                                </template>
                            </q-input>
                        </div>
                        <div class="q-pa-sm col-12">
                            <q-input
                                v-model="filtros.localidade"
                                bg-color="white"
                                class="full-width"
                                color="secondary"
                                dense
                                @keyup="submitOnKey"
                                label="Local"
                                label-color="primary"
                                outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-map-marker" />
                                </template>
                            </q-input>
                        </div>
                    </q-tab-panel>
                    <q-tab-panel class="flex row justify-center" name="veiculos">
                        <div class="q-pa-sm col-12">
                        <q-input
                            v-model="filtros.veiculo.placa"
                            bg-color="white"
                            class="full-width"
                            color="secondary"
                            dense
                            @keyup="submitOnKey"
                            label="Placa"
                            label-color="primary"
                            outlined
                        >
                            <template v-slot:prepend>
                                <q-icon name="mdi-numeric" />
                            </template>
                        </q-input>
                        </div>
                        <div class="q-pa-sm col-12">
                        <q-select
                            :options="opcoes.marcas"
                            option-label="nome"
                            option-value="id"
                            map-options
                            emit-value
                            @update:model-value="atualizaFiltros"
                            v-model="filtros.veiculo.marca"
                            bg-color="white"
                            class="full-width"
                            color="secondary"
                            dense
                            label="Marca"
                            label-color="primary"
                            outlined
                        >
                            <template v-slot:prepend>
                                <q-icon name="mdi-car" />
                            </template>
                        </q-select>
                        </div>
                        <div class="q-pa-sm col-12">
                        <q-select
                            :options="opcoes.modelos"
                            option-label="nome"
                            option-value="id"
                            map-options
                            emit-value
                            v-model="filtros.veiculo.modelo"
                            bg-color="white"
                            class="full-width"
                            color="secondary"
                            dense
                            label="Modelo"
                            label-color="primary"
                            outlined
                        >
                            <template v-slot:prepend>
                                <q-icon name="mdi-car" />
                            </template>
                        </q-select>
                        </div>
                        <div class="q-pa-sm col-12">
                            <q-input
                                v-model="filtros.veiculo.anoDe"
                                bg-color="white"
                                class="full-width"
                                color="secondary"
                                mask="####"
                                dense
                                @keyup="submitOnKey"
                                label="Ano De"
                                label-color="primary"
                                outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-sort-calendar-ascending" />
                                </template>
                            </q-input>
                        </div>
                        <div class="q-pa-sm col-12">
                            <q-input
                                v-model="filtros.veiculo.anoAte"
                                bg-color="white"
                                class="full-width"
                                color="secondary"
                                mask="####"
                                dense
                                @keyup="submitOnKey"
                                label="Ano Até"
                                label-color="primary"
                                outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-sort-calendar-descending" />
                                </template>
                            </q-input>
                        </div>
                        <div class="q-pa-sm col-12">
                            <q-select
                                :options="opcoes.combustivel"
                                v-model="filtros.veiculo.tipoCombustivel"
                                bg-color="white"
                                class="full-width"
                                color="secondary"
                                dense
                                option-label="label"
                                option-value="value"
                                map-options
                                emit-value
                                label="Tipo de Combustível"
                                label-color="primary"
                                outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-gas-station" />
                                </template>
                            </q-select>
                        </div>
                        <div class="q-pa-sm col-12">
                            <q-select
                                :options="opcoes.cambio"
                                v-model="filtros.veiculo.cambio"
                                bg-color="white"
                                class="full-width"
                                color="secondary"
                                dense
                                option-label="label"
                                option-value="value"
                                map-options
                                emit-value
                                label="Câmbio"
                                label-color="primary"
                                outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-car-shift-pattern" />
                                </template>
                            </q-select>
                        </div>
                    </q-tab-panel>
                    <q-tab-panel class="flex row justify-center" name="imoveis">
                        <div class="q-pa-sm col-12 ">
                            <q-input
                                v-model="filtros.imovel.cidade"
                                bg-color="white"
                                class="full-width"
                                color="secondary"
                                dense
                                @keyup="submitOnKey"
                                label="Cidade"
                                label-color="primary"
                                outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-home-city" />
                                </template>
                            </q-input>
                        </div>
                    </q-tab-panel>
                </q-tab-panels>
                <div class="q-px-md q-pb-md full-width flex row">
                    <div class="col col-12 q-pa-xs">
                        <q-btn @click="filtrar" label="Filtrar" color="primary" icon="mdi-filter" class="full-width" />
                    </div>
                    <div class="col col-12 q-pa-xs">
                        <q-btn @click="limparFiltros" label="Limpar Filtros" text-color="primary" icon="mdi-filter-remove" class="full-width" />
                    </div>
                </div>
            </q-form>
        </q-card>
    </div>
</template>
