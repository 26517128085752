import script from "./Telao.vue?vue&type=script&setup=true&lang=js"
export * from "./Telao.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QToggle,QCard,QToolbar,QIcon,QToolbarTitle});
