<script setup>
import { Head, usePage } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../../Layouts/BlankLayout';
import Icone from '../../../Componentes/Global/Icone';
import { useQuasar } from 'quasar';
import { defineProps, ref, computed } from 'vue';
import useRotas from '../../../Composables/useRotas';

const $q = useQuasar();

const usuario = computed(() => usePage().props.value.auth.usuario)
const route = useRotas();

const props = defineProps({
    aguardando: Boolean,
    segundos: [Number]
})
const segundosLocal = ref(props.segundos);
const aguardandoLocal = ref(props.aguardando);
const interval = setInterval(() => {
    segundosLocal.value -= 1;
    if (segundosLocal.value === 0){
        clearInterval(interval);
        aguardandoLocal.value = false
    }
}, 1000)
</script>

<template>
    <Head title="Verifique Seu Email" />
    <BlankLayout>
        <q-page class="flex column justify-center items-center content-center">
            <icone height="7em" width="14em" class="q-mb-md" />
            <h3
                class="text-center text-primary font-weight-light no-margin q-ma-md"
                :class="$q.screen.gt.sm ? 'text-h3' : 'text-h5'"
            >
                Você precisa confirmar o seu email.
            </h3>
            <p style="max-width: 70vw"
               class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md"
            >
                Enviamos um email de confirmação para: <q-chip>{{usuario?.email}}</q-chip>
            </p>
            <p style="max-width: 70vw"
               class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                Acesse o email e clique no botão verificar email para continuar.
            </p>
            <q-btn flat
                   :label="aguardandoLocal? `Aguarde ${segundosLocal} segundos para reenviar..` : 'Enviar Email novamente'"
                   color="primary"
                   class="q-mt-sm"
                   :disable="aguardandoLocal"
                   @click="$inertia.visit(route('verificacao.email.enviar'))"
                   type="button" />
        </q-page>
    </BlankLayout>
</template>
