<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import BlankLayout from '../Layouts/BlankLayout';
import Icone from '../Componentes/Global/Icone';
import { useQuasar } from 'quasar';
import { ref } from 'vue';

const $q = useQuasar();
const dominio = ref(window.doulheTresConfig.dominio)
</script>

<template>
    <Head title="Conta Bloqueada" />
    <BlankLayout>
        <q-page class="flex column justify-center items-center content-center">
            <icone height="7em" width="14em" class="q-mb-md" />
            <h3
                class="text-center text-negative font-weight-light q-ma-md"
                :class="$q.screen.gt.sm ? 'text-h3' : 'text-h5'">
                Conta Bloqueada!
            </h3>
            <p style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                A sua conta foi bloqueada por um administrador.
            </p>
            <p style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                Entre em contato em suporte@{{dominio}}
            </p>
            <q-btn label="Sair" color="primary" @click="$inertia.visit('sair')" />
        </q-page>
    </BlankLayout>
</template>
