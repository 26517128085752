<script setup>
import { ref } from 'vue';
import { Head } from '@inertiajs/inertia-vue3';
import MainLayout from '../../Layouts/MainLayout';

const nomeSite = ref(window.doulheTresConfig.nome);
const dominio = ref(window.doulheTresConfig.dominio);
</script>

<template>
    <Head title="Politica Privacidade"/>
    <MainLayout>
        <q-page>
            <div
                class="flex q-mx-sm column wrap items-center"
            >
                <div
                    class="flex q-pt-lg row"
                >
                    <div
                        class="text-primary q-pa-none full-width"
                    >
                        <h1 class="
                                text-center text-primary text-h4 text-bold full-width
                                q-my-md
                            ">
                            Política de Privacidade
                        </h1>
                    </div>
                </div>
                <div
                    :class="$q.screen.lt.sm ? '' : 'q-px-xl'"
                    class="q-pt-md no-wrap full-width flex row items-center "
                >
                    <div class="col col-12 col-md-12  q-px-md">
                        A presente política de privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e visitantes do site: {{dominio}}, com a finalidade de demonstrar absoluta transparência quanto ao assunto e esclarecer a todos os interessados sobre os tipos de dados que são coletados.
                        <br/><br/>
                        A sua privacidade é importante para nós. É política do {{nomeSite}} respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site {{nomeSite}}, e outros sites que possuímos e operamos.
                        <br/><br/>
                        Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
                        <br/><br/>
                        Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
                        <br/><br/>
                        Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
                        <br/><br/>
                        O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
                        <br/><br/>
                        Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
                        <br/><br/>
                        O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.
                        <br/><br/>
                        <p class="text-bold">
                            Política sobre uso de cookies
                        </p>
                        Nosso site na internet usa cookies. Você poderá ter mais informações a respeito dos cookies, como os usamos e como controlá-los, a seguir:
                        <br/><br/>
                        Ao utilizar este site, você concorda quanto ao uso dos cookies de acordo com este Informativo sobre Cookies. Se você não concordar quanto ao uso destes cookies, poderá desconectá-lo seguindo as instruções deste Informativo, de modo que os cookies deste site não possam ser inseridos no seu dispositivo.
                        <br/><br/>
                        O que é um cookie?
                        <br/><br/>
                        Cookies são pequenos arquivos de texto enviados para o seu equipamento eletrônico quando você visita o site na internet. Os cookies são então devolvidos para o site de origem em cada visita posterior, ou para algum outro site que reconheça aquele cookie. Os cookies funcionam como uma memória para o site, permitindo que este “lembre” de seu equipamento quando de suas visitas posteriores. Os cookies podem também lembrar as suas preferências, melhorar sua experiência de uso, bem como ajustar as propagandas para as preferências que são mais importantes para você. Você poderá obter mais informações sobre os cookies, inclusive quais cookies foram inseridos no seu equipamento e como administrá-los e apagá-los em www.aboutcookies.org.
                        <br/><br/>
                        Cookies utilizados neste site
                        <br/><br/>
                        Utilizamos tanto session cookies (que permanecem até você fechar o seu navegador) e cookies persistentes (que permanecem por um período mais longo e especificado de tempo). Os tipos de cookies utilizados neste site podem, em geral, ser classificados em 3 categorias: Estritamente Necessários, Desempenho e Funcionalidade.
                        <br/><br/>
                        Estritamente necessários
                        <br/><br/>
                        Alguns dos cookies de nosso site são essenciais para que você possa navegar em todo o website e utilizar seus recursos. Esses cookies não coletam informações sobre você que poderiam vir a ser utilizadas para fins de marketing ou para rastrear por onde você navegou na internet.
                        <br/><br/>
                        Funcionalidade
                        <br/><br/>
                        Estes cookies permitem ao nosso site lembrar as escolhas feitas por você e oferecem recursos melhores e mais pessoais.
                        <br/><br/>
                        Como controlar e eliminar cookies
                        <br/><br/>
                        A maioria dos navegadores da internet aceita cookies, mas normalmente você poderá mudar a configuração do navegador para recusar novos cookies, eliminar os já existentes ou simplesmente ser informado quando novos cookies estão sendo enviados para o seu equipamento. Para tanto, siga as instruções fornecidas pelo seu navegador (normalmente encontradas no setor “Help”, “Tools” ou “Edit”). No entanto, esteja ciente de que, se você recusar ou desativar cookies, você poderá perder algumas das funcionalidades do site. Além disso, a desativação de um cookie ou de uma categoria de cookies não elimina o cookie do seu navegador; você mesmo terá que fazer isso a partir do seu navegador.
                        <br/><br/>
                        Mudanças no nosso uso dos cookies
                        <br/><br/>
                        Quaisquer mudanças no uso dos cookies para este site serão informadas aqui, informando sobre qualquer alteração.
                        <br/><br/>
                        Já em relação ao serviço do GOOGLE utiliza-se o serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você.
                        <br/><br/>
                        Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.
                        <br/><br/>
                        Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.
                        <br/><br/>
                        Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.
                        <br/><br/>
                        <p class="text-bold">
                            Compromisso do Usuário
                        </p>
                        O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o {{nomeSite}} oferece no site e com caráter enunciativo, mas não limitativo:
                        <br/><br/>
                        A). Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
                        <br/>
                        B). Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, jogos de sorte ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
                        <br/>
                        C). Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do {{nomeSite}}, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
                        Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário está consentido com a presente Política de Privacidade.
                        <br/><br/>
                        <p class="text-bold">
                            Mais informações
                        </p>
                        Os dados pessoais do usuário visitante são armazenados pela plataforma durante o período necessário para a prestação do serviço ou cumprimento das finalidades previstas no presente documentos, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.
                        <br/><br/>
                        O presente documento foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) e ainda o documento poderá ser atualizado em decorrência de eventual atualização normativa.
                        <br/><br/>
                        Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.
                        <br/><br/>
                        Esta política é efetiva a partir de 27 fevereiro de 2024.

                    </div>
                </div>
            </div>
        </q-page>
    </MainLayout>
</template>
