<script setup>
import {computed, defineProps, ref} from 'vue';
import { Screen } from 'quasar';
import CardLoteComitente from './CardLoteComitente';

const props = defineProps({
    lotes: Object,
});

const slide = ref(0);


function paginarItens(itens, porPagina){
    let paginaAtual = 0;
    let paginas = [];
    itens.forEach(item => {
        if(!paginas[paginaAtual]){
            paginas[paginaAtual] = [item]
        } else if (paginas[paginaAtual].length === porPagina) {
            paginaAtual++;
            paginas[paginaAtual] = [item]
        } else {
            paginas[paginaAtual].push(item)
        }
    })
    return paginas;
}

const lotesPaginados = computed(() => {

    const qtdPorPagina = (Screen.lt.sm) ? 1 : (Screen.lt.md) ? 3 : 4;

    return paginarItens(props.lotes, qtdPorPagina)
})

</script>

<template>
    <q-carousel
        v-model="slide"
        ref="carouselLotesComitente"
        animated
        transition-prev="slide-right"
        transition-next="slide-left"
        swipeable
        infinite
        class="full-height full-width rounded-borders"

    >
        <template v-slot:control>
            <q-carousel-control
                position="right"
                :offset="[3, 20]"
                class="q-gutter-xs"
            >
                <div class="full-height flex flex-center">
                    <q-btn
                        size="sm"
                        class="bg-primary"
                        push round text-color="white" icon="mdi-chevron-right"
                        @click="$refs.carouselLotesComitente.next()"
                    />
                </div>
            </q-carousel-control>
            <q-carousel-control
                position="left"
                :offset="[3, 20]"
                class="q-gutter-xs"
            >
                <div class="full-height flex flex-center">
                    <q-btn
                        size="sm"
                        class="bg-primary"
                        push round text-color="white" icon="mdi-chevron-left"
                        @click="$refs.carouselLotesComitente.previous()"
                    />
                </div>
            </q-carousel-control>
        </template>
        <q-carousel-slide v-for="(pagina, index) of lotesPaginados" :key="index" :name="index" class="column no-wrap q-pt-none">
            <div  class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap ">
                <card-lote-comitente v-for="(lote) of pagina" :key="lote.id" :lote-init="lote" :leilao-init="lote.leilao" :mostrar-leilao="true" />
            </div>
        </q-carousel-slide>



    </q-carousel>

</template>
