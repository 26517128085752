<script setup>
import { Head, useForm } from '@inertiajs/inertia-vue3';
import MainLayout from '../../Layouts/MainLayout';
import useRotas from '../../Composables/useRotas';

const route = useRotas();

const form = useForm({
    nome: null,
    email: null,
    telefone: null,
    mensagem: null,
})

</script>

<template>
    <Head title="Fale Conosco"/>
    <MainLayout>
        <q-page>
            <div
                class="flex q-mx-sm column wrap items-center"
            >
                <div
                    class="flex q-pt-lg row"
                >
                    <div
                        class="text-primary q-pa-none full-width"
                    >
                        <h1 class="
                                text-center text-primary text-h4 text-bold full-width
                                q-my-md
                            ">
                            Fale Conosco
                        </h1>
                        <h2 class="
                                text-center text-primary text-h5 full-width
                                q-my-md
                            ">
                            Prencha seus dados e nossa equipe irá
                            entrar em contato com você o quanto antes.
                        </h2>
                    </div>
                </div>
                <div
                    :class="$q.screen.lt.sm ? '' : 'q-px-xl'"
                    class="q-pt-md no-wrap full-width flex row items-center "
                >
                    <div class="flex col col-12 q-px-md">
                        <h6 class="
                                text-center text-primary text-h6 full-width
                                q-my-md
                            ">
                            Preencha o formulário e entraremos em contato
                        </h6>
                        <q-form
                            @submit="form.post(route('institucional.faleConosco.enviar'))"
                            class="full-width q-px-md"
                        >
                            <q-input
                                v-model="form.nome"
                                class="q-mb-sm"
                                filled
                                label="Seu Nome"
                                hint="Nome completo"
                                lazy-rules
                                :rules="[ val => val && val.length > 0 || 'Por favor, digite um nome']"
                            >
                                <template v-slot:append>
                                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                                </template>
                            </q-input>
                            <q-input
                                v-model="form.email"
                                class="q-mb-sm"
                                filled
                                type="email"
                                label="Email"
                                hint="Digite seu email"
                                unmasked-value
                                lazy-rules
                                :rules="[
                                        val => val && val.length > 0 || 'Por favor, digite um email',
                                   ]"
                            >
                                <template v-slot:append>
                                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                                </template>
                            </q-input>
                            <q-input
                                filled
                                class="q-mb-sm"
                                v-model="form.telefone"
                                mask="(##) #####-####"
                                hint="Primeiro DD e depois o número: (##) #####-####"
                                label="Telefone"
                                unmasked-value
                                lazy-rules
                                :rules="[
                                        val => val && val.length > 0 || 'Por favor, digite um telefone',
                                    ]"
                            >
                                <template v-slot:append>
                                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                                </template>
                            </q-input>
                            <q-input
                                filled
                                type="textarea"
                                class="q-pb-none"
                                v-model="form.mensagem"
                                label="Mensagem"
                                unmasked-value
                                lazy-rules
                                :rules="[
                                        val => val && val.length > 0 || 'Por favor, digite o nome da empresa comitente',
                                   ]"
                            >
                                <template v-slot:append>
                                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                                </template>
                            </q-input>
                            <div class="flex row justify-around q-mt-md q-pb-md">
                                <q-btn style="flex:3.5;" type="submit" color="primary">
                                    Enviar
                                </q-btn>
                                <q-btn
                                    class="q-ml-sm"
                                    @click="form.reset()"
                                    style="flex: 1.5;"
                                    label="Limpar" type="reset"
                                    text-color="primary"
                                />
                            </div>
                        </q-form>
                    </div>
                </div>
            </div>
        </q-page>
    </MainLayout>
</template>
