import script from "./ListaCarouselLotesComitentes.vue?vue&type=script&setup=true&lang=js"
export * from "./ListaCarouselLotesComitentes.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCarousel,QCarouselControl,QBtn,QCarouselSlide});
