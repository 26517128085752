import { computed, ref } from 'vue';
import useUtils from './useUtils';

export default function useFiltroLotes(lotes, abrir) {
    const {
        formatadorLote
    } = useUtils();

    const filtroOpcoesSeletor = ref('');
    const filtrarOpcoesSeletor = (val, update) => {
        update(() => {
            filtroOpcoesSeletor.value = val;
        })
    }

    const opcoesSeletor = computed(
        () => lotes.value.filter(
            (l) =>
                !filtroOpcoesSeletor.value
                || l.titulo
                .toString()
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                    filtroOpcoesSeletor.value
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
                || formatadorLote(l.sequencia)
                .includes(
                    filtroOpcoesSeletor.value
                )
        ).map(
            (l) => ({ label: `${formatadorLote(l.sequencia)} - ${l.titulo}`, value: l.id })
        )
    );

    const modeloSeletor = computed({
        get: () => null,
        set: (val) => {
            abrir(val.value)
        }
    });

    return {
        modeloSeletor,
        opcoesSeletor,
        filtroOpcoesSeletor,
        filtrarOpcoesSeletor
    }
}
