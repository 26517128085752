import script from "./CardLeilao.vue?vue&type=script&setup=true&lang=js"
export * from "./CardLeilao.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QResponsive,QCarousel,QCarouselControl,QBtn,QBadge,QIcon,QCarouselSlide,QImg,QSpinner,QTooltip,QSeparator,QList,QItem,QItemSection,QItemLabel,QCardActions});
