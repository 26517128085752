<script setup>
import ItemTexto from './ItemTexto';
import useRotas from '../../../../../Composables/useRotas';

const route = useRotas();
</script>

<template>
    <div class="flex row justify-start">
        <item-texto texto="Home" :href="route('home')" />
        <item-texto texto="Quero Vender" :href="route('institucional.queroVender')" />
        <item-texto texto="Blog" :href="route('institucional.links.blog')"/>
        <item-texto texto="Ajuda" :href="route('institucional.ajuda')" />
        <item-texto texto="Fale Conosco" :href="route('institucional.faleConosco')" />
    </div>
</template>
