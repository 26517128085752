<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import MainLayout from '../../Layouts/MainLayout';
import { useQuasar } from 'quasar';

const $q = useQuasar();

const FaqQuestions = [
    {
        label: 'O que é a Sato Leilões?',
        resposta: 'Fundada pelo leiloeiro Antonio Hissao Sato Junior e atuante no mercado desde 2004,' +
            ' a Sato Leilões conta com um histórico de mais 575 mil bens comercializados,' +
            ' entre eles veículos, imóveis, inservíveis, máquinas e equipamentos industriais e muito mais.',
    },
    {
        label: 'Como fico sabendo dos novos eventos realizados na Sato Leilões?',
        resposta: 'Para ficar por dentro de todos os leilões da Sato, você pode cadastrar seu e-mail para receber' +
            ' nossas <b> NEWSLETTER </b>, assim receberá todas as nossas oportunidades semanalmente!' +
            ' Pode também acompanhar nossas redes sociais  <b>(@satoleiloes) </b>, onde fazemos postagens de todos os' +
            ' nossos leilões!',
    },
    {
        label: 'Como faço para me cadastrar na Sato Leilões?',
        resposta: 'É bem simples, na parte superior direita de nosso site tem o botão <b> “CADASTRE-SE” </b>,' +
            ' clique e inicie o preenchimento de seus dados, será necessário confirmar o seu e-mail e o seu celular,' +
            ' feito isso, anexe os documentos e aguarde a nossa liberação!',
    },
    {
        label: 'Como faço para alterar minha senha?',
        resposta: 'Para alterar a sua senha, será necessário clicar em <b> "Esqueci minha senha" </b>' +
            ' na tela de <b> LOGIN </b> e aguardar o e-mail com as instruções para redefinição de senha.',
    },
];
const FaqQuestions2 = [
    {
        label: 'Posso visitar os produtos antes da compra?',
        resposta: 'Sim, é possível a visitação! \n' +
            'Verifique no edital do leilão quais são as condições para que possa ir pessoalmente visualizar o bem que' +
            ' pretende adquirir, estamos à disposição também através do telefone <b> (11) 4223-4343 </b>para passar as' +
            ' informações e realizar os agendamentos de visitas!',
    },
    {
        label: 'Quais são as formas de pagamentos na Sato Leilões?',
        resposta: 'As formas de pagamento variam conforme a determinação da empresa que disponibiliza o bem para que' +
            ' façamos o leilão. Porém os pagamentos mais comuns são à vista e à prazo, mediante entrada.',
    },
    {
        label: 'Existe alguma de taxa de pagamentos?',
        resposta: 'O mais comum é a comissão do leiloeiro sendo de 5% (podendo variar dependendo do leilão),' +
            ' calculado em cima do valor arrematado. Em leilões de bens diversos e veículos é comum existir a taxa' +
            ' administrativa ou taxa de pátio. Todas as taxas, incluindo a comissão do leiloeiro, estarão previstas' +
            ' previamente no edital de cada leilão, pois os valores são passíveis de alteração.',
    },
];
</script>

<template>
    <Head title="Ajuda" />
    <MainLayout>
        <q-page>
            <div>
                <div class="flex justify-center">
                    <h1 class="
                          text-center text-primary text-h4 text-bold full-width
                          q-my-md q-pt-lg
                      ">
                        Perguntas frequentes
                    </h1>
                    <h2 class="
                          text-center text-primary text-h5 full-width
                          q-my-md
                      ">
                        Repostas para as dúvidas mais comuns de nossos usuários!
                    </h2>
                </div>
                <div style="min-height: 40vh">
                    <div
                        :class="$q.screen.lt.sm ? 'column' : ''"
                        class="flex"
                    >
                        <div
                            class="flex q-mt-sm"
                            style="flex:1;">
                            <q-expansion-item
                                v-for="(FaqQuestion, id) in FaqQuestions"
                                :key="id"
                                :label="FaqQuestion.label"
                                class="full-width"
                                expand-separator
                                header-class="text-primary"
                                icon="mdi-chat-question"
                            >
                                <q-card>
                                    <q-card-section v-html="FaqQuestion.resposta">

                                    </q-card-section>
                                </q-card>
                            </q-expansion-item>
                        </div>
                        <div
                            :class="$q.screen.lt.sm ? '' : 'q-mt-sm'"
                            class="flex"
                            style="flex:1;"
                        >
                            <q-expansion-item
                                v-for="(FaqQuestion, id) in FaqQuestions2"
                                :key="id"
                                :label="FaqQuestion.label"
                                class="full-width"
                                expand-separator
                                header-class="text-primary"
                                icon="mdi-chat-question"
                            >
                                <q-card>
                                    <q-card-section v-html="FaqQuestion.resposta">

                                    </q-card-section>
                                </q-card>
                            </q-expansion-item>
                        </div>
                    </div>
                </div>
            </div>
        </q-page>
    </MainLayout>
</template>
