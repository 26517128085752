import { ref } from 'vue';
import useRotas from './useRotas';

export default function useListagemLotesInfinita(leilao) {
    const route = useRotas();

    const infiniteScroll = ref(null);
    const tudoCarregado = ref(false);
    const lotesCarregados = ref([]);

    const loadData = async (index, done) => {
        console.log('Trigger Scroll...');
        if (!tudoCarregado.value){
            console.log('Requisitando lotes...');
            const lotes = await (await fetch(route('leilao.lotes', {
                idLeilao: leilao.value.id,
                _query: {
                    page: index
                }
            }), {
                method: 'GET',
                credentials: 'include'
            })).json();
            console.log(`${lotes.length} Lotes carregados...`);
            lotesCarregados.value.push(...lotes)
            if (lotes.length === 0){
                console.log('Lotes esgotados...');
                tudoCarregado.value = true;
                infiniteScroll.value.stop()
            }
            done()
        } else {
            console.log('Sem mais lotes...');
            infiniteScroll.value.stop()
            done()
        }
    }

    return {
        infiniteScroll,
        tudoCarregado,
        lotesCarregados,
        loadData
    }
}
