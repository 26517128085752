import script from "./Principal.vue?vue&type=script&setup=true&lang=js"
export * from "./Principal.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QBadge,QIcon,QTooltip,QBtn});
