<script setup>
import { ref, computed } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';
import useRotas from '../../../Composables/useRotas';
import { Inertia } from '@inertiajs/inertia';

const route = useRotas();

const termos = computed(() => usePage().props.value?.termo)

const seachContent = ref('');
seachContent.value = termos.value;
const search = () => {
    Inertia.visit(route('pesquisar', {
        _query: {
            termos: seachContent.value
        }
    }));
}
</script>

<template>
    <q-toolbar dense>
        <q-form class="full-width" @submit="search">
            <div class="flex row justify-center full-width">
                <q-input
                    v-model="seachContent"
                    bg-color="white"
                    color="secondary"
                    dense
                    filled
                    label="O que você está procurando?"
                    label-color="primary"
                    :style="!$q.screen.lt.md ? 'width: 70vw;' : 'width: 90vw;'"
                >
                    <template v-slot:append>
                        <q-btn color="primary" icon="mdi-magnify" class="cursor-pointer"
                               @click="search"
                               flat round>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </q-form>
    </q-toolbar>
</template>
