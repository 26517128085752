import './styles/quasar.sass'
import iconSet from 'quasar/icon-set/mdi-v6.js'
import lang from 'quasar/lang/pt-BR.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/mdi-v6/mdi-v6.css'
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';;

export default {
    config: {
        brand: {
            primary: window.doulheTresConfig.primary_color,
            secondary: '#BCCF30',
            accent: '#BCCF30',
            dark: '#1d1d1d',
            positive: '#BCCF30',
            negative: '#FF0000',
            info: '#31CCEC',
            warning: '#F2C037'
        }
    },
    plugins: {
        Notify,
        Dialog,
        Loading
    },
    lang: lang,
    iconSet: iconSet
}
