<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import MainLayout from '../../Layouts/MainLayout';
import useRotas from '../../Composables/useRotas';

const route = useRotas();
</script>

<template>
    <Head title="Recebemos sua mensagem"/>
    <MainLayout>
        <q-page class="flex column justify-center items-center">
            <h1 class="
                                text-center text-primary text-h4 text-bold full-width
                                q-my-md
                            ">
                Recebemos sua mensagem
            </h1>
            <h2 class="
                                text-center text-primary text-h5 full-width
                                q-my-md
                            ">
                A nossa equipe já recebeu a sua mensagem, em breve entraremos em contato.
            </h2>
            <q-btn label="Voltar para a home" @click="$inertia.visit(route('home'))" color="primary"  />
        </q-page>
    </MainLayout>
</template>
