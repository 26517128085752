<script setup>
import Icone from '../../Global/Icone';
import BlocoUsuario from './Principal/BlocoUsuario';
import BotaoEntrar from './Principal/BotaoEntrar';
import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';
import useUtils from '../../../Composables/useUtils';
import { Notify } from 'quasar';

const autenticado = computed(() => usePage().props.value.auth.autenticado)

const { timestampParaStringRelogio, segundosParaIntervaloRelogio } = useUtils();

const refBlocoUsuario = ref(null);
const refBotaoEntrar = ref(null);
const widthBlocoUsuario = computed(() => refBlocoUsuario.value?.offsetWidth)
const widthBotaoEntrar = computed(() => refBotaoEntrar.value?.offsetWidth)
const offset = computed(() => widthBlocoUsuario.value || widthBotaoEntrar.value)

const tempoServidor = computed(() => timestampParaStringRelogio(window.serverTime.value))
const tempoServidorUltimaAtt = computed(() => timestampParaStringRelogio(window.serverTimeLastUpdate.value))
const segundosDiff = computed(() => window.serverTime.value - window.serverTimeLastUpdate.value)
const tempoServidorAtrasado = computed(() => segundosDiff.value > 60)
const intervaloDesAtt = computed(() => segundosParaIntervaloRelogio(Math.abs(segundosDiff.value)))

const notificacoesModel = ref(!localStorage.getItem('muteNotifications'));
const notificacoes = computed({
    get: () => notificacoesModel.value,
    set: (val) => {
        notificacoesModel.value = val;
        if (!val) {
            localStorage.setItem('muteNotifications', '1')
            Notify.create({
                message: 'Notificações desativadas!',
                icon: 'mdi-bell-off-outline',
                color: 'positive'
            });
        } else {
            localStorage.removeItem('muteNotifications')
            Notify.create({
                message: 'Notificações ativadas!',
                icon: 'mdi-bell-outline',
                color: 'positive'
            });
        }
    }
});
</script>

<template>
    <q-toolbar class="bg-white text-primary" dense>
        <div class="flex row justify-between full-width">
            <div class="flex row justify-start items-center" :style="'width:'+ offset + 'px'">
                <q-badge :color="tempoServidorAtrasado ? 'warning' : 'primary'" rounded style="font-size: 12px;padding: 6px" >
                    <q-icon v-if="tempoServidorAtrasado" name="mdi-sort-clock-ascending" class="q-pr-xs" /> {{ tempoServidor }}
                    <q-tooltip>
                        Ultima sincronização em: {{tempoServidorUltimaAtt}} (há {{intervaloDesAtt}})
                    </q-tooltip>
                </q-badge>
            </div>
            <div class="flex row justify-center items-center">
                <icone/>
            </div>
            <div class="flex row justify-end">
                <q-btn v-if="notificacoes" @click="notificacoes=!notificacoes" class="text-primary q-mr-sm" icon="mdi-bell-outline" flat round>
                    <q-tooltip>
                        Desativar notificações
                    </q-tooltip>
                </q-btn>
                <q-btn v-else class="text-primary q-mr-sm" @click="notificacoes=!notificacoes" icon="mdi-bell-off-outline" flat round>
                    <q-tooltip>
                        Ativar notificações
                    </q-tooltip>
                </q-btn>
                <div v-if="autenticado" ref="refBlocoUsuario" class="flex row justify-between items-center content-center">
                    <bloco-usuario />
                </div>
                <div v-else ref="refBotaoEntrar" class="flex row justify-between items-center content-center">
                    <botao-entrar />
                </div>
            </div>
        </div>
    </q-toolbar>
</template>
