import { computed, ref, watch, onBeforeUnmount } from 'vue';
import useUtils from './useUtils';

export default function useLoteLocalComUpdates(props) {
    const lote = ref(props.loteInit);

    // console.log(lote.value);
    const {
        limparEntradasNulas,
        mergeDeep
    } = useUtils();

    watch(props, () => {
        const limpo = limparEntradasNulas(props.loteInit);
        lote.value = mergeDeep(lote.value, limpo);
    })

    const leilao = computed(() => lote.value.leilao)

    let callbackStatus = (e) => {
        console.log('Lote ' + lote.value.id + ' recebeu atualização via socket')
        lote.value.status_lote = e.status;
        lote.value.proximoStatus = e.proximoStatus;
    }

    let callbackUpdate = (e) => {
        console.log('Lote ' + lote.value.id + ' recebeu atualização via socket')
        lote.value = mergeDeep(lote.value, e.dados);
    }

    window.Echo.channel('realtime.status.lote')
    .listen('.lote.' + lote.value.id + '.status.atualizado', (e) => {
        callbackStatus(e);
    });

    window.Echo.channel('realtime.update.lote')
    .listen('.lote.' + lote.value.id + '.atualizado', (e) => {
        callbackUpdate(e);
    });

    onBeforeUnmount(() =>{
        callbackStatus = () => {};
        callbackUpdate = () => {};
    })

    return {
        lote,
        leilao
    }
}
