<script setup>
import MenuTopo from '../../Menus/Cabecalho/Topo/Menu';
import { useQuasar } from 'quasar';
const $q = useQuasar();
</script>

<template>
    <q-toolbar v-if="$q.screen.gt.sm" style="min-height: 35px!important; padding: 0;" dense>
        <menu-topo />
    </q-toolbar>
</template>
