import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';

export default function useGlobals() {

    const favoritos = computed(() => usePage().props.value.favoritos)
    const logoLeiloeiro = ref(window.doulheTresConfig.logo_url);
    const nomeLeiloeiro = ref(window.doulheTresConfig.nome);
    const urlAtual = computed(() => window.location.href);
    const logado = computed(() => usePage().props.value.auth?.autenticado);
    const emAnalise = computed(() => usePage().props.value.auth?.cliente?.status_cliente?.identificador !== 'LIBERADO')
    const usernameLogado = computed(() => usePage().props.value.auth?.usuario?.username);
    return {
        favoritos,
        logoLeiloeiro,
        nomeLeiloeiro,
        urlAtual,
        logado,
        emAnalise,
        usernameLogado
    }
}
