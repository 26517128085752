<script setup>
import ItemIcone from './ItemIcone';
import useRotas from '../../../../../Composables/useRotas';

const route = useRotas();
</script>

<template>
    <div class="flex row justify-start">
        <item-icone nome="mdi-whatsapp" blank :href="route('institucional.links.whatsapp')"/>
        <item-icone nome="mdi-instagram" blank :href="route('institucional.links.instagram')" />
        <item-icone nome="mdi-twitter" blank :href="route('institucional.links.twitter')" />
        <item-icone nome="mdi-youtube" blank :href="route('institucional.links.youtube')" />
        <item-icone nome="mdi-facebook" blank :href="route('institucional.links.facebook')" />
        <item-icone nome="mdi-linkedin" blank :href="route('institucional.links.linkedin')" />
    </div>
</template>
