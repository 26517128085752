<script setup>
import {defineProps, defineEmits, computed, ref, watch} from 'vue';
import { useQuasar } from 'quasar';
import { useForm } from '@inertiajs/inertia-vue3';
import useRotas from '../../Composables/useRotas';
import useUtils from '../../Composables/useUtils';
import moment from 'moment';
import { Inertia } from '@inertiajs/inertia';

const props = defineProps({
    leilao: Object,
    lote: Object,
    habilitado: Boolean,
    mostrarLance: Boolean,
    automatico: Boolean,
    lanceAutomatico: Object,
    lanceProprio: Boolean,
})

const route = useRotas();

const emit = defineEmits(['update:modelValue', 'atualizacaoLanceAutomatico']);

const confirm = ref(false);

const popupModelProxy = computed({
    get: () => props.mostrarLance,
    set: (val) => emit('update:modelValue', val)
});

const dataFormatada = ref(null);
const loading = ref(false);

const form = useForm({
    valor: props.lote.proximoLance,
    dataHora: null,
    confirmacao: false,
    pctVista: (props.leilao.permitir_parcelamento == '1') ? props.leilao.porcentagem_a_vista : 100,
    qtdParcelas: null,
    automatico: props.automatico,
    valorMaximo: null,
});

const temLanceAutomatico = ref(false);
const lanceAutomaticoMinimo = ref(0);

const removerLanceAutomatico = async () => {

    await Inertia.post(route('auditorio.excluiLanceAutomatico', {
        idLeilao: props.leilao.id,
        idLote: props.lote.id
    }), {
        onSuccess: () => {
            confirm.value = false;
        },
    });

};

watch(props, () => {

    temLanceAutomatico.value = props.lanceAutomatico?.tem_lance_automatico;

    if(temLanceAutomatico.value){

        if(+props.lanceAutomatico?.valor_maximo <= +Number.parseFloat(props.lote.proximoLance)){

            if(!props.lanceProprio){

                lanceAutomaticoMinimo.value = formatador.format(Number.parseFloat(props.lote.proximoLance) + (Number.parseFloat(props.lote.incrementoLote)*2)) ;
                form.valorMaximo = formatador.format(Number.parseFloat(props.lote.proximoLance) + (Number.parseFloat(props.lote.incrementoLote)*2));

            }else{

                lanceAutomaticoMinimo.value = formatador.format(Number.parseFloat(props.lote.proximoLance) + Number.parseFloat(props.lote.incrementoLote));
                form.valorMaximo = formatador.format(Number.parseFloat(props.lote.proximoLance) + Number.parseFloat(props.lote.incrementoLote));
            }

        }else{

            if(!props.lanceProprio) {

                lanceAutomaticoMinimo.value = formatador.format(Number.parseFloat(props.lote.proximoLance) + (Number.parseFloat(props.lote.incrementoLote)*2)) ;
                form.valorMaximo = formatador.format(props.lanceAutomatico?.valor_maximo);
            }else{

                lanceAutomaticoMinimo.value = formatador.format(Number.parseFloat(props.lote.proximoLance) + Number.parseFloat(props.lote.incrementoLote));
                form.valorMaximo = formatador.format(props.lanceAutomatico?.valor_maximo);
            }
        }


    }else{

        if(!props.lanceProprio){

            lanceAutomaticoMinimo.value = formatador.format(Number.parseFloat(props.lote.proximoLance) + (Number.parseFloat(props.lote.incrementoLote)*2));
            form.valorMaximo = formatador.format(Number.parseFloat(props.lote.proximoLance) + (Number.parseFloat(props.lote.incrementoLote)*2));
        }else{

            lanceAutomaticoMinimo.value = formatador.format(Number.parseFloat(props.lote.proximoLance) + Number.parseFloat(props.lote.incrementoLote));
            form.valorMaximo = formatador.format(Number.parseFloat(props.lote.proximoLance) + Number.parseFloat(props.lote.incrementoLote));
        }

    }


});

const valorAVista = computed(() => props.lote.proximoLance * (form.pctVista/100));
const valorAVistaFmt = computed(() => formatador.format(valorAVista.value));
const valorRestante = computed(() => props.lote.proximoLance - valorAVista.value);
const valorRestanteFmt = computed(() => formatador.format(valorRestante.value));
const parcelamentoPequeno = computed(() => ((valorRestante.value/2) < props.leilao.valor_minimo_parcelamento));
const fnPct = val => `${val}%`;

const optParcelas = computed(() => {
    const arrayVazio = Array.from({length: +props.leilao.maximo_parcela}, (v, k) => k+1);
    return arrayVazio.filter((v) => {
        return ((valorRestante.value/(v)) > props.leilao.valor_minimo_parcelamento) && v > 1;
    }).map((v) => {
        const opt = {};
        opt.value = v;
        opt.label = `${opt.value} Parcela${(v > 1) ? 's' : ''} de ${formatador.format(valorRestante.value/opt.value)} + ajuste de acordo com o edital`
        return opt;
    });
})

watch(form, () => {
    if (!optParcelas.value.map((o) => o.value).includes(form.qtdParcelas)){
        form.qtdParcelas = null;
    }
    if (form.hasErrors){
        Inertia.reload();
        form.clearErrors();
    }
})

const valorLocal = ref(props.lote.proximoLance);

watch(props, () => {
    if (valorLocal.value !== props.lote.proximoLance){
        form.valor = props.lote.proximoLance;
        valorLocal.value = props.lote.proximoLance;
        form.confirmacao = false;
    }
})

setInterval(() => {
    dataFormatada.value = moment().format('DD/MM/YYYY HH:mm:ss')
    form.dataHora = moment().format('YYYY-MM-DD HH:mm:ss')
}, 1000)

const $q = useQuasar();
const { formatador, desformatador } = useUtils();

const incrementarValorMaximo = () => {
    form.valorMaximo = formatador.format(Number.parseFloat(desformatador(form.valorMaximo)) + Number.parseFloat(props.lote.incrementoLote) );
};
const decrementarValorMaximo = () => {

    if(Number.parseFloat(desformatador(lanceAutomaticoMinimo.value)) < Number.parseFloat(desformatador(form.valorMaximo))) {
        form.valorMaximo = formatador.format(Number.parseFloat(desformatador(form.valorMaximo)) - Number.parseFloat(props.lote.incrementoLote));
    }else{
        form.valorMaximo = lanceAutomaticoMinimo.value;
    }

};


const labelAceite =  computed(() => {

    if(temLanceAutomatico.value){

        return (props.lanceProprio) ? 'Estou ciente de que minha configuração ' + ((+props.leilao.venda_direta === 1) ? ' da proposta automática' : ' de lance automático') + ' para este lote será alterado.' : 'Estou ciente que após o ' + ((+props.leilao.venda_direta === 1) ? ' proposta ser registrada a mesma' : ' lance ser registrado o mesmo') + ' não poderá ser retirado, e de que a configuração do automático será gravado.';

    }else{

        if(props.lanceProprio){
            return 'Estou ciente de que a configuração' + ((+props.leilao.venda_direta === 1) ? ' da proposta automática' : ' do lance automático') + ' será gravado, e os ' + ((+props.leilao.venda_direta === 1) ? ' propostas registradas' : ' lances registrados') + ' a partir dele não poderão ser retirados.';
        }else{
            return (props.automatico) ? 'Estou ciente que após o lance ser registrado o mesmo não poderá ser retirado, e de que a configuração do lance automático será gravado.' : (+props.leilao.venda_direta === 1) ? 'Estou ciente que após a proposta ser registrada a mesma não poderá ser retirada.' : 'Estou ciente que após o lance ser registrado o mesmo não poderá ser retirado.';
        }

    }

});

const labelBotaoConfirmar =  computed(() => {

    if(temLanceAutomatico.value){

        return (props.lanceProprio) ? 'Alterar Configuração do Automático' : (+props.leilao.venda_direta === 1) ? 'Alterar Configuração e Registrar Proposta' : 'Alterar Configuração e Registrar Lance';

    }else{

        if(props.lanceProprio){
            return 'Gravar Configuração';
        }else{
            return (props.automatico) ? (+props.leilao.venda_direta === 1) ? 'Registrar Proposta e Gravar Configuração dos Próximos' :  'Registrar Lance e Gravar Configuração dos Próximos' : (+props.leilao.venda_direta === 1) ? 'Registrar Proposta' : 'Registrar Lance';
        }

    }

});


</script>

<template>
    <q-dialog v-model="popupModelProxy" :maximized="$q.screen.lt.md">
            <q-card class="flex no-wrap column" style="min-width: 50vw;min-height: 40vh; overflow-y: auto;overflow-x: hidden">
                <q-toolbar class="bg-primary text-white">
                    <q-icon name="mdi-gavel" />
                    <q-toolbar-title>
                        <template v-if="+leilao.venda_direta === 1">
                            Fazendo uma Oferta
                        </template>
                        <template v-else>
                            Dando Lance
                        </template>
                         em "{{lote.titulo}}"
                    </q-toolbar-title>
                    <q-btn flat round color="white" icon="mdi-window-close" @click="popupModelProxy = false"/>
                </q-toolbar>

                <q-form @submit="form.post(route((automatico) ? 'auditorio.lanceAutomatico' : 'auditorio.lance', {
                    idLeilao: leilao.id,
                    idLote: lote.id
                }),{
                    preserveScroll: true,
                    onStart: ()=> { loading = true },
                    onSuccess: () => { popupModelProxy = false;
                                       emit('atualizacaoLanceAutomatico');
                                       form.confirmacao = false;
                    },
                    onFinish: ()=> { loading = false }
                })">

                    <div>
                        <q-card-section v-if="automatico">
                            <div class="text-overline text-primary">Lote: {{lote.titulo}}</div>
                            <div class="text-h5 q-mt-none q-mb-xs"
                                 v-if="automatico">
                                {{ (temLanceAutomatico) ? 'Alterar Configuração do Automático' : 'Configuração do Automático' }}
                            </div>

                            <div class="text-subtitle1 text-bold q-mt-sm q-mb-xs text-primary"
                                 v-if="automatico">
                                <template v-if="+leilao.venda_direta === 1">
                                    Detalhes da Proposta Automática:
                                </template>
                                <template v-else>
                                    Detalhes do Lance Automático:
                                </template>

                            </div>
                            <div class="text-caption text-grey-9"
                                 v-if="automatico">
                                <div v-if="temLanceAutomatico">

                                    <template v-if="+leilao.venda_direta === 1">Sua proposta automática registrada</template><template v-else>Seu lance automático registrado</template>
                                     é de:  {{formatador.format(props.lanceAutomatico?.valor_maximo)}} alterar para: {{formatador.format(desformatador(form.valorMaximo))}}
                                    <br/>
                                </div>
                                <template v-if="!lanceProprio">
                                Ao gravar o valor máximo do automático, será feito  <template v-if="+leilao.venda_direta === 1">uma proposta</template><template v-else>um lance</template> conforme informações abaixo.
                                </template>
                                <div class="flex full-witdh" :class="$q.screen.lt.sm ? 'column' : 'row' ">
                                    <div style="flex:1">
                                        <q-input class="text-h5 q-my-md"
                                                 filled
                                                 v-model="form.valorMaximo"
                                                 :label="(+leilao.venda_direta === 1) ? 'Valor Máximo da Proposta':'Valor Máximo do Lance'"
                                                 prefix="R$"
                                                 mask="###.###.###.###,##"
                                                 reverse-fill-mask
                                                 input-class="text-right"
                                                 readonly
                                        >
                                            <template v-slot:append>
                                                <q-btn icon="mdi-minus-box" @click="decrementarValorMaximo" round flat dense/>
                                                <q-btn icon="mdi-plus-box" @click="incrementarValorMaximo" round flat dense/>
                                            </template>
                                        </q-input>
                                    </div>


                                </div>
                            </div>

                        </q-card-section>
                        <q-separator />
                        <q-card-section v-if="!lanceProprio">
                            <div class="text-overline text-primary"
                            v-if="!automatico"
                            >Lote: {{lote.titulo}}</div>
                            <div class="text-h5 q-mt-none q-mb-xs">
                                <template v-if="+leilao.venda_direta === 1">
                                    Fazendo uma Oferta
                                </template>
                                <template v-else>
                                    Dando Lance
                                </template>
                                de {{formatador.format(lote.proximoLance)}} + {{leilao.porcentagem_comissao||5}}% de Comissão
                            </div>
                            <div class="text-subtitle1 text-bold q-mt-sm q-mb-xs text-primary">
                                <template v-if="+leilao.venda_direta === 1">
                                    Detalhes da Proposta:
                                </template>
                                <template v-else>
                                    Detalhes do Lance:
                                </template>
                            </div>
                            <div class="text-caption text-grey-9">
                                <template v-if="+leilao.venda_direta === 1">
                                    A proposta
                                </template>
                                <template v-else>
                                    O Lance
                                </template>
                                será registrado no lote {{lote.sequencia.toString().padStart(3, '0')}} - {{lote.titulo}} do <template v-if="+leilao.venda_direta === 1">evento</template><template v-else>leilão</template> {{leilao.titulo}} no horario {{dataFormatada}} com o valor de {{formatador.format(lote.proximoLance)}}
                            </div>
                            <template v-if="+leilao.venda_direta === 1 && 1 === 2">
                                <div class="text-subtitle1 text-bold q-mt-sm q-mb-xs text-primary">
                                    Regras de Participação:
                                </div>
                                <div class="text-caption text-grey-9">
                                    - Somente empresas que possuem o CNAE 45.11 - Comércio a varejo e por atacado de veículos automotores poderão enviar propostas.<br/>
                                    - Somente lojistas selecionados pelo time comercial poderão participar dos eventos.<br/>
                                    - O participante deve estar com a situação regular na Receita Federal.<br/>
                                </div>
                            </template>
                            <div class="text-subtitle1 text-bold q-mt-sm q-mb-xs text-primary">
                                Modo de Pagamento:
                            </div>
                            <div v-if="leilao.permitir_parcelamento == '1'"
                                 class="text-subtitle1 q-mb-xs text-primary">
                                <p class="no-margin" v-if="leilao.porcentagem_a_vista > 0">O pagamento dentro deste <template v-if="+leilao.venda_direta === 1">evento</template><template v-else>leilão</template> deve ser feito com um minimo de <b>{{leilao.porcentagem_a_vista}}% a vista</b> no arremate.</p>
                                <p class="no-margin" v-else-if="leilao.maximo_parcela > 0">O pagamento dentro deste <template v-if="+leilao.venda_direta === 1">evento</template><template v-else>leilão</template> pode ser feito em ate <b>{{leilao.maximo_parcela}} parcelas</b>.</p>
                                <p class="no-margin" v-if="leilao.porcentagem_a_vista > 0 && leilao.maximo_parcela > 0">O pagamento do valor restante pode ser feito em ate <b>{{leilao.maximo_parcela}} parcelas</b>. A comissão do <template v-if="+leilao.venda_direta === 1">evento</template><template v-else>leiloeiro</template> <b>não é parcelavel</b>.</p>
                                <p class="no-margin" v-if="leilao.valor_minimo_parcelamento > 0">Só poderá ser parcelado valor a partir de <b>{{formatador.format(leilao.valor_minimo_parcelamento)}}</b>.</p>
                                <p class="text-subtitle1 q-mt-sm text-bold">Quanto deseja pagar a vista?</p>
                                <div class="q-px-md q-mb-md">
                                    <q-slider v-model="form.pctVista"
                                              :min="0"
                                              :inner-min="leilao.porcentagem_a_vista"
                                              :max="100"
                                              :step="5"
                                              markers
                                              :marker-labels="fnPct"
                                              :label-value="valorAVistaFmt"
                                              label
                                    />
                                </div>
                                <p v-if="!parcelamentoPequeno && valorRestante > 0" class="text-subtitle1 q-mt-sm text-bold">Em quantas parcelas deseja pagar o valor restante ({{valorRestanteFmt}})?</p>
                                <div class="q-px-md q-mb-md" v-if="!parcelamentoPequeno&& valorRestante > 0">
                                    <q-select v-model="form.qtdParcelas"
                                              label="Selecione a Quantidade de Parcelas"
                                              map-options
                                              emit-value
                                              :options="optParcelas"
                                    />
                                </div>
                                <div class="q-px-md q-mb-md text-subtitle1 text-primary" v-else-if="valorRestante == 0">
                                    Valor será pago a vista em totalidade.
                                </div>
                                <div class="q-px-md q-mb-md text-subtitle1 text-primary flex column justify-center items-center" v-else>
                                    O valor {{valorRestanteFmt}} é muito baixo para parcelamento, por favor, selecione um valor menor a vista, ou pague a vista em totalidade.
                                    <q-btn @click="form.pctVista = 100" label="Pagar a vista em Totalidade" size="sm" color="primary" />
                                </div>
                            </div>
                            <div v-else
                                 class="text-subtitle1 q-mt-sm q-mb-xs text-primary">
                                O pagamento dentro deste <template v-if="+leilao.venda_direta === 1">evento</template><template v-else>leilão</template> é <b>apenas a vista</b>.
                            </div>

                        </q-card-section>
                    </div>
                    <div>
                        <q-card-section>
                            <div>
                                <q-checkbox :true-value="true"
                                            :false-value="false"
                                            :label="labelAceite"
                                            v-model="form.confirmacao" />
                            </div>
                        </q-card-section>
                    </div>
                    <q-space></q-space>
                    <q-card-actions class="full-width">
                        <div class="flex row full-width">
                            <div class="flex column col-12 col-md-6"
                                 v-if="temLanceAutomatico">
                                <q-btn :disable="!form.confirmacao"
                                       color="red"
                                       icon="mdi-trash-can"
                                       label="Excluir Automático"
                                       class="q-mx-sm q-mb-sm"
                                       stretch
                                       @click="confirm = true"
                                />
                            </div>
                            <div :class="(!temLanceAutomatico) ? 'flex column col-12 col-md-12' : 'flex column col-12 col-md-6'">
                                <q-btn
                                    :disable="(!form.confirmacao || (form.pctVista < 100 && (!form.qtdParcelas || parcelamentoPequeno)))"
                                    :loading="loading"
                                    class="q-mx-sm q-mb-sm"
                                    color="primary"
                                    :label="labelBotaoConfirmar"

                                    type="submit"
                                    stretch
                                >
                                    <q-tooltip v-if="parcelamentoPequeno">
                                        O valor {{valorRestanteFmt}} é muito baixo para parcelamento, por favor, selecione um valor menor a vista, ou pague em totalidade.
                                    </q-tooltip>
                                </q-btn>
                            </div>
                        </div>
                    </q-card-actions>
                </q-form>

                <q-dialog v-model="confirm" persistent>
                    <q-card>
                        <q-card-section class="row items-center">
                            <q-avatar icon="mdi-trash-can" color="red" text-color="white" class="q-mb-sm"/>
                            <span class="q-ml-sm">Ao clicar em confirmar sua configuração de <template v-if="+leilao.venda_direta === 1">proposta</template><template v-else>lance</template> automático será excluída, mas <template v-if="+leilao.venda_direta === 1">as propostas</template><template v-else>os lances</template> que foram registrados a partir dele serão mantidos.</span>
                        </q-card-section>

                        <q-card-actions align="right">
                            <q-btn flat label="Cancelar" color="primary" v-close-popup />
                            <q-btn flat label="Confirmar" color="red" @click="removerLanceAutomatico" />
                        </q-card-actions>
                    </q-card>
                </q-dialog>

            </q-card>
    </q-dialog>

</template>
