import { Inertia } from '@inertiajs/inertia';
import useRotas from './useRotas';
import { computed, ref, watch } from 'vue';
import useGlobals from './useGlobals';
import useUtils from './useUtils';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default function useLeilao(leilao) {
    const $q = useQuasar();

    const {
        timestampsParaIntervaloRelogio
    } = useUtils();

    const route = useRotas();

    const abrirLeilao = () => {
        if(+leilao.value.externo === 1){
            window.open(leilao.value.link_parceiro, '_blank')
            return;
        }
        Inertia.visit(route('leilao', {idLeilao: leilao.value.id}))
    };

    const abrirLeilaoNovaAba = () => {
        if(+leilao.value.externo === 1){
            window.open(leilao.value.link_parceiro, '_blank')
            return;
        }
        window.open(route('leilao', {idLeilao: leilao.value.id}), '_blank');
    };

    const {
        favoritos,
    } = useGlobals();

    const fullscreen = ref(false);
    const autoplay = ref(false);
    const slide = ref(1);
    const favoritado = ref(favoritos.value.leiloes.map(f => f.id_leilao).includes(leilao.value.id.toString()));
    const verDocumentos = ref(false);

    watch(favoritado, async (val) => {
        const csrfToken = document.head.querySelector("[name~=csrf-token][content]").content;

        if (val){
            $q.loading.show({
                message: 'Salvando seu favorito...'
            });

            await (await fetch(route('utilidades.favoritar', {idLeilao: leilao.value.id}), {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRF-Token": csrfToken
                }
            })).json();

            $q.loading.hide();

            return;
        }
        $q.loading.show({
            message: 'Removendo seu favorito...'
        });

        await (await fetch(route('utilidades.desFavoritar', {idLeilao: leilao.value.id}), {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRF-Token": csrfToken
            }
        })).json();

        $q.loading.hide();
    })

    const timer = computed(() => timestampsParaIntervaloRelogio(window.serverTime.value ,leilao.value.proximoStatus?.timestamp) || null);

    const regulamento = computed(() => {
        return leilao.value.arquivos_do_leilao?.filter(a => a.tipo === '0')[0];
    });
    const catalogo = computed(() => {
        return leilao.value.arquivos_do_leilao?.filter(a => a.tipo === '1')[0];
    });
    const edital = computed(() => {
        return leilao.value.arquivos_do_leilao?.filter(a => a.tipo === '2')[0];
    });
    const outrosDocumentos = computed(() => {
        return leilao.value.arquivos_do_leilao;
    });

    return {
        abrirLeilao,
        abrirLeilaoNovaAba,
        fullscreen,
        autoplay,
        slide,
        favoritado,
        timer,
        regulamento,
        catalogo,
        edital,
        outrosDocumentos,
        verDocumentos
    }
}
