<script setup>
import Topo from './Partes/Topo';
import Principal from './Partes/Principal';
import PesquisaLote from './Partes/PesquisaLote';
import {usePage} from "@inertiajs/inertia-vue3";
import {Inertia} from "@inertiajs/inertia";
import useRotas from "../../Composables/useRotas";

const route = useRotas();

const {
    props
} = usePage();

const abrirStream = () => {
    Inertia.visit(route('leilao', {
        idLeilao: props?.value?.live?.id
    }))
}
</script>

<template>
    <q-header class="bg-primary text-white">
        <topo />
        <principal />
        <pesquisa-lote />
        <q-banner
            v-if="props?.live && !+(props?.live?.id === +props?.leilaoInit?.id)"
            dense
            class="bg-secondary text-primary text-center q-py-xs"
            rounded
            inline-actions
            dark>
            <div
                style="display: flex;flex-direction:row;justify-content: center;align-items: center"
            >
                <q-icon
                    size="sm"
                    name="mdi-record"
                    color="red"
                    class="q-ma-none q-pa-none" />
                <p class="inline-block q-ma-none">
                    Estamos Ao-Vivo
                </p>
                <q-btn
                    flat
                    @click="abrirStream"
                    dense
                    class="q-ml-xs"
                    color="primary"
                    label="Acompanhar" />
            </div>
        </q-banner>
    </q-header>
</template>
