<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import {computed, defineProps, onBeforeUnmount, onMounted} from 'vue';
import useAuditorio from '../Composables/useAuditorio';
import { useQuasar } from 'quasar';
import CardLances from "../Componentes/Auditorio/CardLances";
import CardDetalhesLeiloeiro from "../Componentes/Auditorio/CardDetalhesLeiloeiro";
import BtnLote from "../Componentes/Auditorio/BtnLote";
import SlimNoFooterLayout from "../Layouts/SlimNoFooterLayout";
import useLeilaoLocalComUpdates from "@/Composables/useLeilaoLocalComUpdates";

const props = defineProps({
    leilao: Object,
    loteSelecionadoInit: Object,
    lotesInit: Array,
    habilitado: Boolean
})

const {
    leilao
} = useLeilaoLocalComUpdates({
    leilaoInit: props.leilao
})

const $q = useQuasar();

const {
    mostrarImagens,
    seguirLeiloeiro,
    lotes,
    abrirFixarEvent,
    loteLeiloeiro,
    lote
} = useAuditorio(
    props.leilao,
    props.loteSelecionadoInit,
    props.lotesInit
)

let leiloeiroAndou = (e) => {
    loteLeiloeiro.value = e.id;
    if (seguirLeiloeiro.value) {
        $q.loading.show({
            message: '<p>Seguindo leiloeiro...<br/> Para desativar este comportamento, clique no seletor no canto superior direito da tela</p>',
            html: true
        });
        lote.value = e.dados;
        setTimeout(() => {
            $q.loading.hide();
            document.getElementsByClassName('atual')[0].scrollIntoView()
        }, 800)

    }
}

window.Echo.channel('auditorio.' + props.leilao.id)
.listen('.leiloeiroAndou', (e) => {
    leiloeiroAndou({ id: e.novoLote, dados: e.dadosNovoLote });
});

onMounted(() => {
    document.body.style.overflowY = 'hidden';
})

onBeforeUnmount(() =>{
    leiloeiroAndou = () => {};
    document.body.style.overflowY = 'auto';
})

const urlVideo = computed(() => {
    return `${leilao.value.link_transmissao}?rel=0&autoplay=1&loop=1`;
});

</script>
<template>
    <Head :title="'Painel de Leiloeiro - ' + lote.titulo"/>
    <SlimNoFooterLayout>
        <q-page class="flex column">
            <div class="full-width flex row justify-between items-center">
                <div class="flex column q-px-md q-mt-lg items-start">
                    <h6 class="q-ma-none text-primary">
                        Painel de leiloeiro - Leilão {{ leilao.id }}  "{{ leilao.titulo }}"
                    </h6>
                    <div class="q-mb-none" v-html="leilao.descricao"></div>
                </div>
                <div class="full-height q-mr-md flex column justify-center">
                    <q-toggle
                        :true-value="true"
                        :false-value="false"
                        v-model="seguirLeiloeiro"
                        label="Seguir Moderador"/>
                </div>
            </div>

            <div class="full-width flex row no-wrap q-pt-md q-px-xs" style="flex: 1">
                <div class="flex column col-8 q-pa-sm">
                    <card-detalhes-leiloeiro :key="lote.id" :lote-init="lote" :leilao-init="leilao"
                                             :habilitado="habilitado">
                        <template v-slot:botoes-lote>
                            <btn-lote @selecionado="abrirFixarEvent"
                                      :lote-leiloeiro="loteLeiloeiro"
                                      v-for="loteCard in lotes"
                                      :key="loteCard.id"
                                      :lote-init="loteCard"
                                      :leilao="leilao"
                                      :mostrar-imagens="mostrarImagens"
                                      :lote-selecionado="lote"
                            />
                        </template>
                    </card-detalhes-leiloeiro>
                </div>

                <div class="flex column col-4 full-height" >

                    <div class="column full-width"
                         scroll-region>
                        <div class="flex column full-width q-pa-sm">
                            <card-lances :key="lote.id" :lote-init="lote" :leilao-init="leilao"
                                         :habilitado="habilitado" />
                        </div>
                    </div>

                    <div class="column full-width q-pb-md" style="height: 40vh;">
                        <q-video
                            v-if="leilao.link_transmissao"
                            :key="`live-${leilao.id}`"
                            class="full-height"
                            :src="urlVideo"
                        />
                    </div>
                </div>
            </div>
        </q-page>
    </SlimNoFooterLayout>
</template>
