<script setup>
import { Head, useForm, usePage } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../../Layouts/BlankLayout';
import Icone from '../../../Componentes/Global/Icone';
import { computed, defineProps, ref } from 'vue';
import useRotas from '../../../Composables/useRotas';
import { useQuasar } from 'quasar';

const usuario = computed(() => usePage().props.value.auth.usuario)

const form = useForm({
    codigo: null,
})

const props = defineProps({
    aguardando: Boolean,
    segundos: [Number]
})

const route = useRotas();
const $q = useQuasar();
const segundosLocal = ref(props.segundos);
const aguardandoLocal = ref(props.aguardando);
const interval = setInterval(() => {
    segundosLocal.value -= 1;
    if (segundosLocal.value === 0){
        clearInterval(interval);
        aguardandoLocal.value = false
    }
}, 1000)
</script>

<template>
    <Head title="Verifique seu Telefone" />
    <BlankLayout>
        <q-page class="flex column justify-center items-center content-center">
            <icone height="7em" width="14em" class="q-mb-md" />
            <h3
                class="text-center text-primary font-weight-light no-margin q-ma-md"
                :class="$q.screen.gt.sm ? 'text-h3' : 'text-h5'">
                Agora vamos confirmar o seu telefone.
            </h3>
            <p style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                Enviamos um sms com um codigo de confirmação para o telefone: <q-chip>{{usuario?.telefone_celular}}</q-chip>
            </p>
            <p style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                Digite o código abaixo para continuar e enviar os seus documentos.
            </p>
            <q-form @submit="form.post(route('verificacao.telefone.verificar'))" class="q-px-md">
                <q-input v-model="form.codigo"
                         counter
                         label="Digite o Código"
                         :maxlength="6"/>
                <q-btn label="Enviar"
                       color="primary"
                       class="full-width"
                       type="submit" />
                <q-btn flat
                       :label="aguardandoLocal? `Aguarde ${segundosLocal} segundos para reenviar..` : 'Enviar SMS novamente'"
                       color="primary"
                       class="full-width q-mt-sm"
                       :disable="aguardandoLocal"
                       @click="$inertia.visit(route('verificacao.telefone.enviar'))"
                       type="button" />
            </q-form>
        </q-page>
    </BlankLayout>
</template>
