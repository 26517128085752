import script from "./LeilaoLayout.vue?vue&type=script&setup=true&lang=js"
export * from "./LeilaoLayout.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer});
