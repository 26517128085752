import script from "./CardDetalhesLeiloeiro.vue?vue&type=script&setup=true&lang=js"
export * from "./CardDetalhesLeiloeiro.vue?vue&type=script&setup=true&lang=js"

import "./CardDetalhesLeiloeiro.vue?vue&type=style&index=0&id=45032be3&lang=css"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QCarousel,QCarouselSlide,QImg,QSpinner,QList,QItem,QItemSection,QIcon,QItemLabel,QToolbar,QToolbarTitle});
