<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import { defineProps } from 'vue';
import BlankLayout from '../Layouts/BlankLayout';
import useRotas from '../Composables/useRotas';
const route = useRotas();

defineProps({
    report: Object,
    mostrar: Boolean
});
</script>

<template>
    <Head title="Erro" />
    <blank-layout>
        <q-page class="flex column justify-center items-center bg-primary">
            <h1 class="q-my-sm text-center text-white text-h2">Parece que algo deu errado</h1>
            <p class="q-my-sm text-center text-white text-subtitle1">
                Não se preocupe, nossa equipe já foi notificada.
            </p>
            <p class="q-my-xs text-center text-white text-subtitle1">
                Caso o problema persista, entre em contato com o suporte.
            </p>
            <p class="q-my-xs text-center text-white text-subtitle2">
                O seu protocolo de suporte é: <q-chip>{{report.id}}</q-chip>
            </p>
            <q-btn label="Voltar para a home"
                   color="white"
                   class="q-mt-md"
                   text-color="primary"
                   @click="$inertia.visit(route('home'))" />
        </q-page>
    </blank-layout>
</template>
