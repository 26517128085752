<script setup>
import { defineProps } from 'vue';
import { Link } from '@inertiajs/inertia-vue3'

const openBlank = (link) => {
    window.open(link, '_blank');
};

defineProps({
    nome: String,
    href: String,
    blank: Boolean
})
</script>

<template>
    <Link
        v-if="!blank"
        as="div"
        :href="href"
        :class="{ 'active': href.endsWith($page.url) }"
        class="flex row justify-start link-topo">
        <q-icon size="23px" :name="nome" class="text-white" />
    </Link>
    <div
        v-else
        @click="openBlank(href)"
        :class="{ 'active': href.endsWith($page.url) }"
        class="flex row justify-start link-topo">
        <q-icon size="23px" :name="nome" class="text-white" />
    </div>
</template>

<style type="text/sass" scoped>
.link-topo{
    padding: 5px 15px;
    cursor: pointer;
    transition: 0.3s;
}
.link-topo:hover{
    background-color: rgba(255, 255, 255, 0.10);
}
.link-topo.active{
    background-color: rgba(255, 255, 255, 0.10);
    color: var(--q-positive);
}
</style>
