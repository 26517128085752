<script setup>
import { defineProps } from 'vue';
import useUtils from '../../Composables/useUtils';
import useLote from '../../Composables/useLote';
import useGlobals from '../../Composables/useGlobals';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import useLeilaoLocalComUpdates from '../../Composables/useLeilaoLocalComUpdates';
import useLeilao from "@/Composables/useLeilao";

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    mostrarLeilao: Boolean
});

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    leilao
} = useLeilaoLocalComUpdates(props)

const {
    getImagem,
    formatDateWithoutSeconds,
    formatador,
    formatadorLote,
    stripHTML,
} = useUtils();

const {
    abrirLote,
    abrirLoteNovaAba,
    fullscreen,
    autoplay,
    slide,
    timerModerador
} = useLote(leilao, lote)

const {
    timer
} = useLeilao(leilao);

const {
    logoLeiloeiro
} = useGlobals();
</script>

<template>
    <div class="q-pa-sm col col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3" style="justify-content: stretch">
        <q-card class="cursor-pointer" :style="$q.screen.lt.sm ? 'width:100%' : ''">
            <q-card-section
                :style="`background-color: ${lote.status_lote?.cor_site};`"
                class="
                      text-subtitle1
                      text-white
                      text-center
                      q-py-none
                      column
                      justify-center
                "
                style="height: 0.5em;"
                @click="abrirLote()" @auxclick="abrirLoteNovaAba()">
            </q-card-section>
            <q-responsive
                :ratio="16/11" style="width: 100%;"
            >
                <q-carousel
                    v-model="slide"
                    v-model:fullscreen="fullscreen"
                    :autoplay="autoplay"
                    :thumbnails="fullscreen"
                    animated
                    control-color="white"
                    control-text-color="primary"
                    control-type="push"
                    height="14em"
                    infinite
                    swipeable
                >
                    <template v-slot:control>

                        <q-carousel-control
                            position="left"
                            :offset="[5, 5]"
                            class="q-gutter-xs"
                        >
                            <div class="full-height flex flex-center">
                                <q-btn
                                    size="sm"
                                    flat
                                    push round dense text-color="primary" icon="mdi-chevron-left"
                                    @click="$refs.carousel.previous()"
                                />
                            </div>
                        </q-carousel-control>
                        <q-carousel-control
                            position="right"
                            :offset="[5, 5]"
                            class="q-gutter-xs"
                        >
                            <div class="full-height flex flex-center">
                                <q-btn
                                    size="sm"
                                    flat
                                    push round dense text-color="primary" icon="mdi-chevron-right"
                                    @click="$refs.carousel.next()"
                                />
                            </div>
                        </q-carousel-control>
                        <q-carousel-control
                            :offset="[5, 5]"
                            position="top-right"
                        >
                            <q-btn
                                flat
                                push round dense
                                :icon="fullscreen ? 'mdi-arrow-u-left-top' : 'mdi-fullscreen'"
                                text-color="primary"
                                @click.stop="fullscreen = !fullscreen"
                                size="sm"
                            >{{ fullscreen ? 'Voltar' : '' }}
                            </q-btn>
                        </q-carousel-control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="bottom-left"
                            v-if="+lote.vistoria_imobiliaria?.desocupado"
                        >
                            <q-badge size="sm" color="primary" rounded>
                                <q-icon name="mdi-home-export-outline" size="xs" class="q-mr-xs"/> Desocupado
                            </q-badge>
                        </q-carousel-control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="bottom-left"
                            v-if="+lote.vistoria_veicular?.sucata"
                        >
                            <q-badge size="sm" color="primary" rounded>
                                <q-icon name="mdi-car-wrench" size="xs" class="q-mr-xs"/> Sucata
                            </q-badge>
                        </q-carousel-control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="bottom-right"
                            v-if="lote.views"
                        >
                            <q-badge size="sm" color="positive" rounded>
                                <q-icon name="mdi-eye" size="xs" class="q-mr-xs"/>{{lote.views}}
                            </q-badge>
                        </q-carousel-control>
                    </template>
                    <q-carousel-slide
                        v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0  ? lote.imagens_lote
            : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))"
                        :key="key"
                        :name="1+key"
                        style="background-position: center;background-size: contain;background-repeat: no-repeat"
                        @click="abrirLote()" @auxclick="abrirLoteNovaAba()"
                        class="q-pa-none">
                        <q-img
                            class="full-width full-height"
                            loading="lazy"
                            :src="getImagem(imagem?.arquivo, fullscreen, leilao.status_leilao?.identificador)"
                            :ratio="16/11"
                        >
                            <template v-slot:error>
                                <div class="absolute-full flex flex-center bg-primary text-white">
                                    Houve um problema carregando a imagem
                                </div>
                            </template>
                            <template v-slot:loading>
                                <div class="absolute-full flex column flex-center bg-white text-primary">
                                    <img :src="logoLeiloeiro" style="width: 40%" />
                                    <p class="q-mt-sm">
                                        <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                    </p>
                                </div>
                            </template>
                        </q-img>
                        <div v-if="fullscreen" class="absolute-bottom custom-caption q-pb-xl">
                            <q-card class="q-ma-lg q-ma-md-xl q-pa-md" style="overflow-y: scroll;max-height: 15vh;">
                                <div class="text-h4 text-primary text-uppercase">{{ lote.titulo }}</div>
                                <div class="text-subtitle2" v-html="lote.descricao"></div>
                            </q-card>
                        </div>
                        <div v-else class="absolute-bottom seq-caption q-pb-xl" :style="(+leilao.externo === 1) ? 'display: none;' : ''">
                            <h6 v-if="+leilao.externo === 0">{{formatadorLote(lote.sequencia)}}</h6>
                        </div>
                    </q-carousel-slide>
                </q-carousel>
            </q-responsive>
            <q-card-section
                :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="q-py-xs"
                @click="abrirLote()" @auxclick="abrirLoteNovaAba()"
            >
                <div class="row no-wrap items-center"
                     @click="abrirLote()" @auxclick="abrirLoteNovaAba()">
                    <div class="col desc-scroll text-h6" style="max-width: 83vw">
                        <p class="q-mb-none text-subtitle2 text-primary text-uppercase" style="line-height: 1.5em; height: 3em; overflow: hidden">{{stripHTML(lote.titulo)}}</p>
                        <q-tooltip>
                            {{formatadorLote(lote.sequencia)}} {{stripHTML(lote.titulo)}}
                        </q-tooltip>
                    </div>
                </div>
            </q-card-section>

            <q-list>

                <q-item
                    :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLote()" @auxclick="abrirLoteNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon size="sm" color="primary" name="mdi-calendar"/>
                        <q-icon size="sm" color="positive" name="mdi-currency-usd"/>
                    </q-item-section>

                    <q-item-section class="text-body2"
                                    style="min-height: 55px;"
                                    :style="[
                                        {'text-decoration' : +leilao.primeiro_leilao_concluido === 1 ? 'line-through' : 'none'},
                                        {'min-height' : (+leilao.dois_leiloes !== 1 && +leilao.externo === 0) ? '125px' : '55px'}
                                    ]"
                    >
                        <q-item-label>
                            <template v-if="+leilao?.venda_direta === 1">
                                Fechamento em:
                            </template>
                            <template v-else>
                                <template v-if="leilao.dois_leiloes === '1'">1º</template> Leilão -
                            </template>
                            {{ formatDateWithoutSeconds(leilao.data_hora_inicio) }}
                        </q-item-label>
                        <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" class="text-body2 text-grey-8" v-if="+leilao.externo === 1">Entre e confira os valores.</q-item-label>
                        <q-item-label class="text-body2 text-grey-8" v-else>
                            <template v-if="+leilao?.venda_direta === 1">
                                Valor de Referência: {{ formatador.format(lote.lance_inicial) }}
                            </template>
                            <template v-else-if="+lote.lance_inicial > 0">
                                Lance inicial: {{ formatador.format(lote.lance_inicial) }}
                            </template>
                            <template v-else>Entre e confira.</template>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    v-if="leilao.dois_leiloes === '1'"
                    @click="abrirLote()" @auxclick="abrirLoteNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon size="sm" color="primary" name="mdi-calendar"/>
                        <q-icon size="sm" color="positive" name="mdi-currency-usd"/>
                    </q-item-section>

                    <q-item-section class="text-body2" style="min-height: 55px;">
                        <q-item-label :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">2º Leilão - {{ formatDateWithoutSeconds(leilao.data_hora_inicio_segundo_leilao) }}</q-item-label>
                        <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" class="text-body2 text-grey-8" v-if="+leilao.externo === 1">Entre e confira os valores.</q-item-label>
                        <q-item-label class="text-body2 text-grey-8" v-else>
                            <template v-if="+lote.lance_inicial_segundo_leilao > 0">
                                Lance inicial: {{ formatador.format(lote.lance_inicial_segundo_leilao) }}
                            </template>
                            <template v-else>Entre e confira.</template>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    v-if="+leilao.externo === 1"
                    clickable
                    @click="abrirLeilao()" @auxclick="abrirLeilaoNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon color="positive" :name="+leilao.tipo_leilao === 6 ? 'mdi-scale-balance' : 'mdi-handshake'"/>
                    </q-item-section>

                    <q-item-section>
                        <q-item-label>{{ +leilao.tipo_leilao === 6 ? 'Leilão TRF' : 'Leilão Parceiro' }}</q-item-label>
                        <q-item-label caption>{{ +leilao.tipo_leilao === 6 ? 'Este leilão será feito em site externo' : 'O leilão será feito em site parceiro' }}</q-item-label>
                    </q-item-section>
                    <q-tooltip>
                        {{ +leilao.tipo_leilao === 6 ? 'O site externo será aberto numa nova guia' : 'O site parceiro será aberto numa nova guia' }}
                    </q-tooltip>
                </q-item>

            </q-list>

            <q-separator/>

            <q-card-section
                :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center q-pa-xs"
                v-if="+leilao.fechamento_automatico === 1 && +leilao.tipo_fechamento === 1"
                @click="abrirLote()" @auxclick="abrirLoteNovaAba()"
            >
                <q-item
                    class="text-center text-primary"
                    :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLote()" @auxclick="abrirLoteNovaAba()"
                >


                    <q-item-section>
                        <q-item-label overline :class="'text-' + lote.proximoStatus.cor">
                            {{ lote?.leilao?.proximoStatus?.texto }}
                        </q-item-label>
                        <q-item-label v-if="lote?.leilao?.proximoStatus?.timestamp" class="text-bold"> {{ timer }} </q-item-label>
                    </q-item-section>
                </q-item>
            </q-card-section>

            <q-card-section
                :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center q-pa-xs"
                v-if="+leilao.fechamento_automatico === 0 && lote.timer_moderador && timerModerador"
                @click="abrirLote()" @auxclick="abrirLoteNovaAba()"
            >
                <q-item
                    class="text-center text-primary"
                    :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLote()" @auxclick="abrirLoteNovaAba()"
                >
                    <q-item-section>
                        <q-item-label class="text-bold"> {{ timerModerador }} </q-item-label>
                    </q-item-section>
                </q-item>
            </q-card-section>

            <q-separator/>

            <q-card-section
                v-if = '1 === 2'
                :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center q-pa-xs"
                @click="abrirLote(leilao.id)"
            >
                <q-tooltip>
                    Leilão Oficial {{ leilao?.comitente?.nome_fantasia }}
                </q-tooltip>
                <q-responsive :ratio="60/17" style="height: 3em;">
                    <q-img v-if="leilao?.comitente?.arquivo?.logoComitenteUrl"
                        :src="leilao?.comitente?.arquivo?.logoComitenteUrl"
                        fit="contain">
                    </q-img>
                    <div class="flex text-primary items-center justify-center text-center" v-else>
                        {{ leilao?.comitente?.nome_fantasia }}
                    </div>
                </q-responsive>
            </q-card-section>

        </q-card>
    </div>
</template>
<style lang="sass" scoped>
.seq-caption
    text-align: center
    padding: 3px
    color: white
    background-color: rgba(0, 0, 0, .3)
.seq-caption > h6
    margin: 0
.desc-scroll::-webkit-scrollbar
    display: none
</style>
<style>
.cssmarquee {
    overflow: hidden;
    position: relative;
    height: 35px;
}
.cssmarquee p {
    margin: 0;
    padding: 0;
    position: absolute;
    height: 100%;
    text-align: center;
    overflow-x: visible;
    transform:translateX(0%);
    animation: cssmarquee 60s linear infinite;
}
@keyframes cssmarquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
</style>
