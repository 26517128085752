<script setup>
import useRotas from '../../../../Composables/useRotas';
import { useQuasar } from 'quasar';

const route = useRotas();
const $q = useQuasar();
</script>

<template>

    <div class="q-pa-sm">
        <q-btn class="text-primary" icon="mdi-login-variant" clickable flat>
            <label class="cursor-pointer q-ml-md" v-if="$q.screen.gt.sm">Login / Cadastro</label>
            <q-menu>
                <div class="row no-wrap q-pa-md">
                    <div class="column">
                        <div class="text-h6 q-mb-md row justify-center text-primary" style="width: 100%" v-if="$q.screen.lt.md">Login / Cadastro</div>
                        <q-list style="min-width: 200px">
                            <q-item clickable v-close-popup @click="$inertia.visit(route('auth.login'))">
                                <q-item-section avatar>
                                    <q-avatar text-color="primary" icon="mdi-login-variant"></q-avatar>
                                </q-item-section>
                                <q-item-section avatar>
                                    Login
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="$inertia.visit(route('cadastro.seletor'))">
                                <q-item-section avatar>
                                    <q-avatar text-color="primary" icon="mdi-account-circle"></q-avatar>
                                </q-item-section>
                                <q-item-section>Criar Conta</q-item-section>
                            </q-item>

                        </q-list>
                    </div>
                </div>
            </q-menu>
        </q-btn>
    </div>

<!--
    <q-btn class="text-primary" dense flat icon="mdi-login-variant" rounded>
        <template v-if="$q.screen.gt.sm">Login / Cadastro</template>
    </q-btn>
    <q-menu class="text-primary">
        <q-list
            dense
            style="min-width: 100px"
        >
            <q-item v-close-popup clickable @click="$inertia.visit(route('auth.login'))">
                <q-item-section class="text-primary">
                    Login
                </q-item-section>
            </q-item>
            <q-item v-close-popup clickable @click="$inertia.visit(route('cadastro.seletor'))">
                <q-item-section class="text-primary">
                    Criar Conta
                </q-item-section>
            </q-item>
        </q-list>
    </q-menu>
   -->
</template>
