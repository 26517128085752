<script setup>
import { defineProps } from 'vue';
import { Head, useForm } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../Layouts/BlankLayout';
import Icone from '../../Componentes/Global/Icone';
import useRotas from '../../Composables/useRotas';


defineProps({
   token: String
});

const route = useRotas();

const form = useForm({
    senha: null,
    senha_confirmation: null,
})

</script>

<template>
    <Head title="Redefinição de Senha" />
    <BlankLayout>
        <q-page class="flex row justify-center items-center">
            <div class="flex column items-center col-11 col-md-5" >
                <icone height="7em" width="14em" />
                <h6 class="text-center text-primary font-weight-light q-my-md">
                    Redefinição de senha
                </h6>
                <q-form class="full-width" @submit="form.post(route('auth.login.redefinirSenha.salvar', { token }))">
                    <q-input
                        v-model="form.senha"
                        :rules="[
                            val => val && val.length > 0 || 'Por favor digite sua senha',
                            val => val && /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])/.test(val) || 'A senha deve ter ao menos uma letra minuscula, uma maiuscula e um caractere especial'
                        ]"
                        class="full-width"
                        color="primary"
                        dense
                        type="password"
                        label="Digite sua Nova Senha"
                        label-color="primary"
                        lazy-rules
                    />
                    <q-input
                        v-model="form.senha_confirmation"
                        :rules="[
                            val => val && val.length > 0 || 'Por favor digite sua senha',
                            val => val && val === form.senha || 'A senha e confirmação devem ser iguais',
                        ]"
                        type="password"
                        class="full-width"
                        color="primary"
                        dense
                        label="Repita a sua Nova Senha"
                        label-color="primary"
                        lazy-rules
                    />
                    <q-btn
                        class="full-width"
                        color="primary"
                        label="Confirmar alteração de senha"
                        type="submit"
                        :loading="form.processing"
                    />
                    <q-btn
                        class="full-width q-mt-sm"
                        color="primary"
                        flat
                        label="Voltar"
                        type="button"
                        @click="$inertia.visit(route('auth.login'))"
                    />
                </q-form>
            </div>
        </q-page>
    </BlankLayout>
</template>
