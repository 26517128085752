import { Inertia } from '@inertiajs/inertia';
import useRotas from './useRotas';
import {computed, ref} from 'vue';
import useUtils from './useUtils';
import moment from 'moment';
import useGlobals from './useGlobals';

export default function useLote(leilao, lote) {
    const {
        usernameLogado
    } = useGlobals();

    const route = useRotas();

    const abrirLote = () => {
        if(+leilao.value.externo === 1){
            window.open(leilao.value.link_parceiro, '_blank')
            return;
        }
        Inertia.visit(route('lote', {idLeilao: leilao.value.id, idLote: lote.value.id}))
    };
    const abrirLoteNovaAba = () => {
        window.open(route('lote', {idLeilao: leilao.value.id, idLote: lote.value.id}), '_blank')
    };

    const abrirLoteArrematado = () => {
        Inertia.visit(route('minha_conta.loteArrematado', {idLeilao: leilao.value.id, idLote: lote.value.id}))
    };

    const abrirLeilao = () => {
        Inertia.visit(route('leilao', {idLeilao: leilao.value.id}))
    };
    const abrirLeilaoNovaAba = () => {
        window.open(route('leilao', {idLeilao: leilao.value.id}), '_blank')
    };

    const {
        timestampsParaIntervaloRelogio
    } = useUtils();


    const fullscreen = ref(false);
    const autoplay = ref(false);
    const timer = computed(() => timestampsParaIntervaloRelogio(window.serverTimeLeilaoLote.value ,lote.value.proximoStatus?.timestamp) || null);
    const timerModeradorOriginal = computed(() => timestampsParaIntervaloRelogio(window.serverTime.value , moment(lote.value.timer_moderador, 'YYYY-MM-DD HH:mm:ss').unix()) || null);
    const timerModerador = computed(() => timerModeradorOriginal.value === 'Atualizando...' ? false : timerModeradorOriginal.value);
    const slide = ref(1);
    const tab = ref('videos');
    const share = ref(false);
    const lanceAtual = computed(() => lote.value.lances[0]);
    const lanceAtualAutomatico = computed(() => lanceAtual.value?.id_lance_automatico_registrado);
    const lanceUsuario = computed(() => (lanceAtual.value?.cliente?.usuario?.username === usernameLogado.value) ? 'VOCÊ' :  (+lote.value?.leilao?.mostrar_apelido === 0) ? lote.value?.lances[0]?.cliente.id : lote.value?.lances[0]?.cliente.usuario.username);
    const lanceData = computed(() => moment(lanceAtual.value?.data_hora).format('DD/MM/YYYY HH:mm:ss'));
    const lanceProprio = computed(() => lanceAtual.value?.cliente?.usuario?.username === usernameLogado.value);
    const verLances = ref(false);
    const verDocumentos = ref(false);

    const edital = computed(() => {
        return leilao.value.arquivos_do_leilao?.filter(a => a.tipo === '2')[0];
    });
    const outrosDocumentos = computed(() => {
        return lote.value.documentos_lote;
    });
    const vistoria = computed(() => {
        return lote.value?.documentos_lote?.filter(a => a.tipo === '0')[0]
    });

    const link_youtube = computed(() => {

        let link_vistoria_youtube;

        switch (+lote.value?.tipo_lote) {
            case 0:
                link_vistoria_youtube = (lote.value?.vistoria_bens_diversos?.link_youtube) ? lote.value?.vistoria_bens_diversos?.link_youtube : null;
                break;
            case 1:
                link_vistoria_youtube = (lote.value?.vistoria_imobiliaria?.link_youtube) ? lote.value?.vistoria_imobiliaria?.link_youtube : null;
                break;
            case 2:
                link_vistoria_youtube = (lote.value?.vistoria_veicular?.link_youtube) ? lote.value?.vistoria_veicular?.link_youtube : null;
                break;
        }

        if (link_vistoria_youtube){
            if (!link_vistoria_youtube.startsWith('http://') && !link_vistoria_youtube.startsWith('https://')) {
                link_vistoria_youtube = 'https://' + link_vistoria_youtube;
            }


            link_vistoria_youtube = link_vistoria_youtube.replace('youtu.be/', 'www.youtube.com/embed/');
            link_vistoria_youtube = link_vistoria_youtube.replace('?feature=shared', '');
            link_vistoria_youtube = link_vistoria_youtube.replace('watch?v=', 'embed/');

        }

        return (link_vistoria_youtube) ? `${link_vistoria_youtube}?rel=0&autoplay=1&loop=1` : null;
    });

    return {
        abrirLote,
        abrirLoteNovaAba,
        fullscreen,
        autoplay,
        timer,
        slide,
        tab,
        edital,
        outrosDocumentos,
        vistoria,
        share,
        lanceUsuario,
        lanceData,
        lanceProprio,
        verLances,
        verDocumentos,
        abrirLeilao,
        abrirLeilaoNovaAba,
        lanceAtualAutomatico,
        timerModerador,
        abrirLoteArrematado,
        lanceAtual,
        link_youtube
    }
}
