<script setup>
import {defineProps, ref, defineEmits} from 'vue';
import useRotas from '../../Composables/useRotas';
import useUtils from '../../Composables/useUtils';
import Lance from '../Leilao/Lance';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import useGlobals from '../../Composables/useGlobals';
import useLote from '../../Composables/useLote';
//import _ from "lodash";

const emit = defineEmits(['selecionado']);

const route = useRotas();

const props = defineProps({
    leilao: Object,
    loteInit: Object,
    loteSelecionado: Object,
    suprimirVendido: Boolean,
    mostrarImagens: Boolean,
    loteLeiloeiro: Number,
    habilitado: Boolean,
});

//const atualizarLanceAutomatico_debounce = async () => _.debounce( await atualizarLanceAutomatico, 5000);

const {
    getImagem,
    formatDate,
    formatador,
    formatadorLote,
} = useUtils();

const {
    logoLeiloeiro
} = useGlobals();

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    timer,
    slide,
    autoplay,
    lanceProprio,
    timerModerador
} = useLote(props.leilao, lote)

const abrir = async () => {
    emit('selecionado', lote.value.id);
};

const lanceLocal = ref(false);

const carregado = ref(lote.value.carregado);

const renderizar = ref(false);
const entrouNaView = (isVisible) => {
    renderizar.value = isVisible;
}

// watch(timer, () => {
//     console.log(timer.value.padStart(2,'0') + ' - ' + lote.value.proximoStatus?.timestamp + ' - ' + window.serverTime.value);
// });


const visibilityChanged = (isVisible) => {

    if(isVisible){
        (async () => await (await fetch(route('auditorio.dadosLotes', {
            _query: {
                idLote: lote.value.id,
                semImagens: props.mostrarImagens ? 0 : 1
            }
        }), {
            method: 'GET',
            credentials: 'include'
        })).json())().then(resultado => {
            lote.value = { ...lote.value, ...resultado, carregado: true }
            carregado.value = true;

        });
    }
/*
    if (isVisible && !carregado.value){
        (async () => await (await fetch(route('auditorio.dadosLotes', {
            _query: {
                idLote: lote.value.id,
                semImagens: props.mostrarImagens ? 0 : 1
            }
        }), {
            method: 'GET',
            credentials: 'include'
        })).json())().then(resultado => {
            lote.value = { ...lote.value, ...resultado, carregado: true }
            carregado.value = true;

        });

    }
*/

}
</script>

<template>
    <div v-observe-visibility="{
          callback: entrouNaView,
        }" style="min-height: 255px;transition: all .75s ease-in-out" :class="lote.id === loteSelecionado.id ? `atual card-${lote.id}` : `card-${lote.id}`">
        <transition name="q-transition--scale">
        <q-card v-observe-visibility="{
          callback: visibilityChanged,
          throttle: 400,
        }" v-if="renderizar" class="q-ma-sm column" :bordered="lote.id === loteSelecionado.id"
                    style="border-width: 3px; border-top-right-radius: 0;border-top-left-radius: 0"
                    :style="`border-color: ${lote.status_lote?.cor_site};`">
                <q-card-section
                    v-if="carregado"
                    style="height: 20px;transition: all .75s ease-in-out"
                    :style="`background-color: ${lote.status_lote?.cor_site};`"
                    class="full-width text-subtitle1 text-white text-center column justify-center">
                    {{ lote.status_lote?.nome }} {{lote.id === loteSelecionado.id ? ' (Selecionado)' : ''}}
                </q-card-section>
                <q-card-section
                    v-else
                    style="height: 20px;"
                    :style="`background-color: var(--q-primary)`"
                    class="text-subtitle1 text-white text-center column justify-center">
                    Carregando...
                </q-card-section>
                <q-card-section horizontal>
                    <q-card-section class="q-pa-none" v-if="mostrarImagens">
                        <q-carousel
                            v-model="slide"
                            :autoplay="autoplay"
                            animated
                            arrows
                            style="height: 100%; width: 17vw"
                            control-color="white"
                            control-text-color="primary"
                            control-type="push"
                            next-icon="mdi-chevron-right"
                            prev-icon="mdi-chevron-left"
                            infinite
                            swipeable
                            @mouseenter="autoplay = false"
                            @mouseleave="autoplay = true"
                        >
                            <q-carousel-slide
                                v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0  ? lote.imagens_lote : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))"
                                :key="key" :name="1+key"
                                style="background-position: center;background-size: cover;background-repeat: no-repeat"
                                @click="abrir(lote.id)"
                                class="q-pa-none">
                                <q-img
                                    class="full-width full-height"
                                    loading="lazy"
                                    :src="getImagem(imagem?.arquivo, false, lote.status_lote?.identificador)"
                                    :ratio="16/9"
                                >
                                    <template v-slot:error>
                                        <div class="absolute-full flex flex-center bg-primary text-white">
                                            Houve um problema carregando a imagem
                                        </div>
                                    </template>
                                    <template v-slot:loading>
                                        <div class="absolute-full flex column flex-center bg-white text-primary">
                                            <img :src="logoLeiloeiro" style="width: 40%" />
                                            <p class="q-mt-sm">
                                                <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                            </p>
                                        </div>
                                    </template>
                                </q-img>
                                <div class="absolute-bottom seq-caption q-pb-xl">
                                    <h5>{{formatadorLote(lote.sequencia)}}</h5>
                                </div>
                            </q-carousel-slide>
                        </q-carousel>
                    </q-card-section>
                    <q-card-section class="q-pa-none" style="flex: 4">
                        <q-list class="full-width">
                            <q-item clickable @click="abrir(lote.id)">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-label-multiple-outline"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label>{{formatadorLote(lote.sequencia)}} - {{ lote.titulo }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="+leilao.fechamento_automatico === 1 && +leilao.tipo_fechamento === 1 && lote.carregado && lote.proximoStatus.timestamp" clickable @click="abrir(lote.id)">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-timer"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label class="text-bold text-h5 text-primary">{{timer}}</q-item-label>
                                    <q-item-label caption>{{lote.proximoStatus.texto}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="+leilao.fechamento_automatico === 0 && lote.carregado && lote.timer_moderador && timerModerador" clickable @click="abrir(lote.id)">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-timer"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label class="text-bold text-h5 text-primary">{{timerModerador}}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item clickable @click="abrir(lote.id)">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-calendar"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label
                                        :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through'  : ''"
                                        :caption="(+leilao.primeiro_leilao_concluido === 1 && +leilao.dois_leiloes === 1) ? true : false"
                                    >
                                            1º Leilão: {{ formatDate(leilao.data_hora_inicio) }}
                                    </q-item-label>
                                    <q-item-label
                                        :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through'  : ''"
                                        :caption="(+leilao.primeiro_leilao_concluido === 1) ? false : true"
                                        v-if="leilao.dois_leiloes === '1'"
                                        >
                                            2º Leilão: {{ formatDate(leilao.data_hora_inicio_segundo_leilao) }}
                                    </q-item-label>
                                    <q-item-label v-else caption>
                                        Sem Segundo Leilão
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="lote.carregado" clickable @click="abrir(lote.id)">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-cash"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label v-if="!(lote.lanceAtual > 0)">Lance Inicial {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}</q-item-label>
                                    <q-item-label v-if="lote.lanceAtual > 0">Lance Atual {{ formatador.format(lote.lanceAtual) }}</q-item-label>
                                    <q-item-label caption v-if="lote.lanceAtual > 0">Lance Inicial {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1 && +leilao.dois_leiloes === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-else clickable @click="abrir(lote.id)">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-cash"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label><q-skeleton type="text" width="80px" height="16px" /></q-item-label>
                                    <q-item-label caption><q-skeleton type="text" width="80px" height="14px" /></q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="lote.carregado" clickable @click="abrir(lote.id)">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-cash-fast"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label>Próximo Lance {{ formatador.format(lote.proximoLance) }}</q-item-label>
                                    <q-item-label caption>Incremento {{ formatador.format(lote.incrementoLote) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-else clickable @click="abrir(lote.id)">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-cash-fast"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label><q-skeleton type="text" width="80px" height="16px" /></q-item-label>
                                    <q-item-label caption><q-skeleton type="text" width="80px" height="14px" /></q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-card-section>
                    <q-card-actions vertical style="justify-content: center">
                        <q-btn v-if="lote.id === loteSelecionado.id" round icon="mdi-eye" flat color="positive">
                            <q-tooltip>
                                Lote Aberto
                            </q-tooltip>
                        </q-btn>
                        <q-btn v-if="lote.id == loteLeiloeiro" round icon="mdi-gavel" flat color="positive">
                            <q-tooltip>
                                Leiloeiro Aqui
                            </q-tooltip>
                        </q-btn>
                        <q-btn v-if="lote.carregado && lote.carregado && lanceProprio" round icon="mdi-trophy" flat color="positive">
                            <q-tooltip>
                                O Seu lance está vencendo!
                            </q-tooltip>
                        </q-btn>
                    </q-card-actions>
                    <q-card-actions v-if="lote.carregado" vertical align="center" style="justify-content: center">
                        <q-btn v-if="lote.id !== loteSelecionado.id" icon="mdi-menu-right-outline" color="primary" @click="abrir()">
                            <q-tooltip>
                                Selecionar Lote
                            </q-tooltip>
                        </q-btn>
                        <q-btn disable v-else icon="mdi-menu-right" color="primary">
                            <q-tooltip>
                                Lote Selecionado
                            </q-tooltip>
                        </q-btn>

                        <q-btn
                            v-if="!(['ABERTO', 'EM PREGAO', 'DOULHE_UMA', 'DOULHE_DUAS'].includes(lote.status_lote.identificador))"
                            disable
                            icon="mdi-currency-usd"
                            :class="'bg-positive'"
                            class="text-white full-width hide-print"
                        ><q-tooltip>
                            O lote não está recebendo mais lances.
                        </q-tooltip></q-btn>
                        <q-btn v-else-if="lanceProprio" disable icon="mdi-currency-usd" color="positive">
                            <q-tooltip>
                                O seu lance está Vencendo!
                            </q-tooltip>
                        </q-btn>
                        <q-btn v-else @click="lanceLocal = true" icon="mdi-currency-usd" color="positive">
                            <q-tooltip>
                                Dar Lance
                            </q-tooltip>
                        </q-btn>
                    </q-card-actions>
                </q-card-section>
                <Lance
                    v-model="lanceLocal"
                    :leilao="leilao"
                    :lote="lote"
                    :habilitado="habilitado"
                    :automatico="false"
                />
            </q-card>
        </transition>
    </div>
</template>
<style lang="sass" scoped>
.seq-caption
    text-align: center
    padding: 8px
    color: white
    background-color: rgba(0, 0, 0, .3)
.seq-caption > h5
    margin: 0
.nopad > p
    margin: 0
</style>
