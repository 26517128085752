import script from "./Padrao.vue?vue&type=script&setup=true&lang=js"
export * from "./Padrao.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QBanner,QIcon,QBtn});
