<script setup>
import { Head, useForm } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../Layouts/BlankLayout';
import Icone from '../../Componentes/Global/Icone';
import useRotas from '../../Composables/useRotas';
import { ref } from 'vue';

const route = useRotas();

const form = useForm({
    cartaoCnpj: null,
    comprovanteEndEmpresa: null,
    documentoSocios: null,
    contratoSocial: null,
})
const cartaoCnpjRef = ref();
const comprovanteEndEmpresaRef = ref();
const documentoSociosRef = ref();
const contratoSocialRef = ref();
</script>

<template>
    <Head title="Entrar" />
    <BlankLayout>
        <q-page class="flex row justify-center items-center">
            <div class="flex column items-center col-11">
                <icone height="7vw" width="14vw" />
                <h4 class="text-center text-primary font-weight-light no-margin q-ma-md q-py-lg">
                    Envio de Documentos
                </h4>
                <q-form class="full-width" @submit="form.post(route('documentacao.juridica.processar'))">
                    <div class="flex row items-end">
                        <div class="flex column col-12 col-md-4">
                            <h6 class="no-margin q-pl-xs q-pt-md text-primary">Dados da Empresa</h6>
                            <q-file
                                v-model="form.cartaoCnpj" bottom-slots class="q-mx-sm"
                                counter
                                ref="cartaoCnpjRef"
                                dense label="Cartão CNPJ" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { cartaoCnpjRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </div>
                        <div class="flex column col-12 col-md-4">
                            <q-file
                                v-model="form.contratoSocial" bottom-slots class="q-mx-sm"
                                counter
                                ref="contratoSocialRef"
                                dense label="Contrato Social" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { contratoSocialRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </div>
                        <div class="flex column col-12 col-md-4">
                            <q-file
                                v-model="form.comprovanteEndEmpresa" bottom-slots class="q-mx-sm"
                                counter
                                ref="comprovanteEndEmpresaRef"
                                dense label="Comprovante de Endereço da Empresa" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { comprovanteEndEmpresaRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </div>
                    </div>
                    <div class="flex row q-mb-md">
                        <div class="flex column col-12">
                            <h6 class="no-margin q-pl-xs q-pt-md text-primary">Dados dos Proprietários</h6>
                            <q-file
                                v-model="form.documentoSocios" bottom-slots class="q-mx-sm"
                                counter
                                ref="documentoSociosRef"
                                dense label="Documento com Foto" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { documentoSociosRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </div>
                    </div>
                    <q-btn
                        class="full-width"
                        color="primary"
                        label="Enviar"
                        type="submit"
                        :disable="(!form.cartaoCnpj || !form.contratoSocial || !form.documentoSocios || !form.comprovanteEndEmpresa)"
                        :loading="form.processing"
                    />
                </q-form>
            </div>
        </q-page>
    </BlankLayout>
</template>
