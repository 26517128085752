import { ref, watch, onBeforeUnmount } from 'vue';
import useUtils from './useUtils';

export default function useLeilaoLocalComUpdates(props) {
    const leilao = ref(props.leilaoInit);

    const {
        limparEntradasNulas,
        mergeDeep
    } = useUtils();

    watch(props, () => {
        const limpo = limparEntradasNulas(props.leilaoInit);
        leilao.value = mergeDeep(leilao.value, limpo);
    })

    let callbackStatus = (e) => {
        console.log('Leilao ' + leilao.value.id + ' recebeu atualização via socket')
        leilao.value.status_leilao = e.status;
        leilao.value.proximoStatus = e.proximoStatus;
    }

    let callbackUpdate = (e) => {
        console.log('Leilao ' + leilao.value.id + ' recebeu atualização via socket')
        leilao.value = {...leilao.value, ...e.dados};
    }

    window.Echo.channel('realtime.status.leilao')
    .listen('.leilao.' + leilao.value.id + '.status.atualizado', (e) => {
        callbackStatus(e);
    });

    window.Echo.channel('realtime.update.leilao')
    .listen('.leilao.' + leilao.value.id + '.atualizado', (e) => {
        callbackUpdate(e);
    });

    onBeforeUnmount(() =>{
        callbackStatus = () => {};
        callbackUpdate = () => {};
    })

    return {
        leilao
    }
}
