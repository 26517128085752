<script setup>
import { ref, computed, onMounted } from 'vue';
import { Inertia } from '@inertiajs/inertia';
import useFiltroLotes from '../../../Composables/useFiltroLotes';
import useRotas from '../../../Composables/useRotas';
import { usePage } from '@inertiajs/inertia-vue3';

const route = useRotas();

const leilao = computed(() => usePage().props.value.leilaoInit || usePage().props.value.loteInit.leilao);
const lotesSeletor = computed(() => usePage().props.value.lotesIndice || []);

const carregandoLotesSeletor = ref(true);
const {
    modeloSeletor,
    opcoesSeletor,
    filtrarOpcoesSeletor
} = useFiltroLotes(lotesSeletor, (value) => {
    Inertia.visit(route('lote', {idLeilao: leilao.value.id, idLote: value}))
});

onMounted(() => {
    Inertia.reload({
        only: ['lotesIndice'],
        onSuccess: () => {
            carregandoLotesSeletor.value = false
        }
    });
})
</script>

<template>
    <q-toolbar elevated class="bg-primary text-white q-pa-xs shadow-2" dense>
        <div class="flex full-width row justify-center items-center">
            <q-select
                :style="!$q.screen.lt.md ? 'width: 70vw;' : 'width: 90vw;'"
                dense
                filled
                use-input
                bg-color="white"
                color="secondary"
                label-color="primary"
                :loading="carregandoLotesSeletor"
                @filter="filtrarOpcoesSeletor"
                v-model="modeloSeletor"
                :options="opcoesSeletor"
                label="Pesquisar aqui"/>
        </div>
    </q-toolbar>
</template>
