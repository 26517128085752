<script setup>
import { defineProps } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';
const props = defineProps({
   nomeLocal: String,
   endereco: String,
});
const center = { lat: -23.6095384, lng: -46.5697541 };
const markerOptions = { position: center, label: props.nomeLocal.toString().substring(0,1), title: props.nomeLocal };
</script>

<template>
    <GoogleMap
        api-key="AIzaSyCvwQyeB1g-cv1DFYLVEFdxicn7mTcKw9U"
        style="width:100%; height: 450px"
        :center="center"
        :zoom="20"
    >
        <Marker :options="markerOptions" />
    </GoogleMap>
</template>
