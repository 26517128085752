<script setup>
import { Head, usePage } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../../Layouts/BlankLayout';
import Icone from '../../../Componentes/Global/Icone';
import useRotas from '../../../Composables/useRotas';
import { useQuasar } from 'quasar';

const route = useRotas();
const $q = useQuasar();

const mensagemDocumentos = (usePage().props.value.auth?.cliente?.status_cliente?.identificador !== 'LIBERADO');

</script>

<template>
    <Head title="Email Verificado" />
    <BlankLayout>
        <q-page class="flex column justify-center items-center content-center">
            <icone height="7em" width="14em" class="q-mb-md" />
            <h3
                class="text-center text-primary font-weight-light no-margin q-ma-md"
                :class="$q.screen.gt.sm ? 'text-h3' : 'text-h5'">
                Parabéns seu telefone foi confirmado!
            </h3>
            <p
                style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                <span v-if="mensagemDocumentos" >Agora para terminar o seu cadastro precisamos coletar alguns documentos.</span>
                <span v-else>Obrigado pela confirmação de seu telefone.</span>
            </p>
            <q-btn label="Proseguir" color="primary" @click="$inertia.visit(route('home'))"/>
        </q-page>
    </BlankLayout>
</template>
