import {createApp, ref} from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import {createInertiaApp} from '@inertiajs/inertia-vue3';
import { h } from 'vue';
import VueSocialSharing from 'vue-social-sharing'
import { maska } from 'maska'
import NProgress from 'nprogress'
import { ObserveVisibility } from 'vue-observe-visibility'
import Echo from 'laravel-echo';
import { Inertia } from '@inertiajs/inertia'
import { createPinia } from 'pinia'

window.usuarioInteragiu = ref(false);
window.document.body.addEventListener('click', () => window.usuarioInteragiu.value = true);

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: window.doulheTresConfig.pusherKey,
    wsHost: window.doulheTresConfig.pusherHost,
    wsPort: window.doulheTresConfig.pusherPort,
    wssHost: window.doulheTresConfig.pusherHost,
    wssPort: window.doulheTresConfig.pusherPort,
    disableStats: true,
    encrypted: true,
    forceTLS: false
});

window.serverTime = ref(window.serverTimeInicial);
window.serverTimeLastUpdate = ref(window.serverTimeInicial);
window.serverTimeLeilaoLote = ref(window.serverTimeInicial);

setInterval(() => window.serverTime.value++, 1000);
setInterval(() => window.serverTimeLeilaoLote.value++, 1000);
window.Echo.channel('realtime.servertime')
.listen('.servertime.atualizar', (e) => {
    console.log('Servertime sincronizado: ' + e.timestamp)
    window.serverTime.value = e.timestamp;
    window.serverTimeLastUpdate.value = e.timestamp;
});

window.Echo.channel('realtime.servertimeleilaolote')
    .listen('.servertimeleilaolote.atualizar', (e) => {
        console.log('Servertime leilao-lote sincronizado: ' + e.timestamp)
        window.serverTimeLeilaoLote.value = e.timestamp;
});

window.Echo.channel('realtime.mensagemGlobal')
.listen('.notificacao', (e) => {
    if (!localStorage.getItem('muteNotifications') && window.doulheTresConfig.venda_direta === false){
        Notify.create({
            message: e.mensagem,
            icon: e.icone,
            color: e.cor,
            progress: true,
            group: e.grupo ? e.grupo : undefined
        });
        const audio = new Audio('/sfx/notificacao.mp3');
        audio.play();
        const titulo = document.title;
        document.title = '🔔 ' + e.mensagem;
        setTimeout(() => {
            document.title = titulo;
        }, 2600)
    }
});


window.Echo.channel('realtime.mensagemGlobal.vendaDireta')
    .listen('.notificacao', (e) => {
        if (!localStorage.getItem('muteNotifications') && window.doulheTresConfig.venda_direta === true){
            Notify.create({
                message: e.mensagem,
                icon: e.icone,
                color: e.cor,
                progress: true,
                group: e.grupo ? e.grupo : undefined
            });
            const audio = new Audio('/sfx/notificacao.mp3');
            audio.play();
            const titulo = document.title;
            document.title = '🔔 ' + e.mensagem;
            setTimeout(() => {
                document.title = titulo;
            }, 2600)
        }
    });


const pinia = createPinia();

window.document.getElementsByTagName('title')[0].remove();
createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => require(`./Pages/${name}.vue`),
    setup({ el, app, props, plugin }) {
        const vueApp =  createApp({ render: () => h(app, props) })
        .directive('maska', maska)
        .directive('observe-visibility',  {
            beforeMount: (el, binding, vnode) => {
                vnode.context = binding.instance
                ObserveVisibility.bind(el, binding, vnode)
            },
            updated: ObserveVisibility.update,
            unmounted: ObserveVisibility.unbind,
        })
        .use(plugin)
        .use(Quasar, quasarUserOptions)
        .use(VueSocialSharing)
        .use(pinia)
        .mount(el);
        return vueApp;
    },
});

NProgress.configure({
    showSpinner: true,
    parent: '#progressbar',

});

let timeout = null

Inertia.on('start', () => {
    timeout = setTimeout(() => NProgress.start(), 250)
})

Inertia.on('navigate', (event) => {
    window.dataLayer.push({
        'url': event.detail.page.url,
        'event': 'pageview'
    });


    // Código para remover o jivo das páginas Auditorio, Moderador, Leiloeiro e Telão.
    const listaComponentsParaEsconderJivoArray = ['Auditorio', 'Moderador', 'Leiloeiro', 'Telao'];

    if (+(document.getElementsByName("esconde_jivo").length) > 0
        && !listaComponentsParaEsconderJivoArray.includes(event.detail.page.component)
    ) {
        document.getElementById("esconde_jivo").remove();
    }else{
        if(listaComponentsParaEsconderJivoArray.includes(event.detail.page.component)){
            document.head.insertAdjacentHTML("afterend", '<style id="esconde_jivo" name="esconde_jivo">jdiv{opacity:0; pointer-events:none;}</style>');
        }
    }

})
Inertia.on('progress', (event) => {
    if (NProgress.isStarted() && event.detail.progress.percentage) {
        NProgress.set((event.detail.progress.percentage / 100) * 0.9)
    }
})

Inertia.on('finish', (event) => {
    clearTimeout(timeout)
    if (!NProgress.isStarted()) {
        return
    } else if (event.detail.visit.completed) {
        NProgress.done()
    } else if (event.detail.visit.interrupted) {
        NProgress.set(0)
    } else if (event.detail.visit.cancelled) {
        NProgress.done()
        NProgress.remove()
    }
})
