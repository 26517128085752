<script setup>
import { computed, defineEmits, defineProps, watch } from 'vue';
import FormCol from '../../Componentes/Forms/FormCol';

const props = defineProps({
    modelValue: [String, Number, Object],
    loading: Boolean,
    error: Boolean,
    errorMessage: String,
    rules: Array,
    dica: String,
    autocomplete: String,
    type: String,
    label: String,
    icon: String,
    required: Boolean,
    largura: Number,
    mascara: String,
    opcoes: Array,
    data: Boolean,
    readonly: Boolean,
    optionsFn: Function,
    dataDefaultYM: String,
})

const emit = defineEmits(['update:modelValue', 'change'])

const model = computed({
    get: () => props.modelValue,
    set: (val) => {
        emit('update:modelValue', val)
    }
})

watch(props, () =>{
    model.value = props.modelValue;
})
</script>

<template>
    <form-col :largura="largura">
        <template v-if="!opcoes">
            <template v-if="data">
                <q-input
                    v-model="model"
                    :error="error || false"
                    :error-message="errorMessage || ''"
                    :loading="loading || false"
                    :rules="((required || false) ? [
                        val => val && val.length > 0 || 'Campo Obrigatório',
                    ] : []).concat(rules || [])"
                    :autocomplete="autocomplete || ''"
                    :type="type || 'text'"
                    class="q-mx-md q-mt-md"
                    color="primary"
                    dense
                    :hint="dica"
                    :readonly="readonly"
                    :label="label || ''"
                    :mask="mascara || undefined"
                    unmasked-value
                    @change="emit('change')"
                    style="flex: 1;"
                >
                    <template v-slot:prepend>
                        <q-icon :name="icon"/>
                    </template>
                    <template v-slot:append>
                        <q-btn round flat class="bg-grey-2" size="10px" icon="mdi-calendar">
                            <q-popup-proxy ref="qDateProxy" cover transition-hide="scale" transition-show="scale">
                                <q-date
                                    years-in-month-view
                                    :options="optionsFn"
                                    v-model="model"
                                    :default-year-month="dataDefaultYM"
                                    mask="DD/MM/YYYY">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup color="primary" flat label="Fechar"/>
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-btn>
                        <q-icon color="red" size="15px" name="mdi-asterisk" v-if="required"/>
                    </template>
                </q-input>
            </template>
            <template v-else>
                <q-input
                    v-model="model"
                    :error="error || false"
                    :error-message="errorMessage || ''"
                    :loading="loading || false"
                    :rules="((required || false) ? [
                        val => val && val.length > 0 || 'Campo Obrigatório',
                    ] : []).concat(rules || [])"
                    :autocomplete="autocomplete || ''"
                    :hint="dica"
                    :type="type || 'text'"
                    class="q-mx-md q-mt-md"
                    color="primary"
                    dense
                    @change="emit('change')"
                    :readonly="readonly"
                    :label="label || ''"
                    :mask="mascara || undefined"
                    unmasked-value
                    style="flex: 1;"
                >
                    <template v-slot:prepend>
                        <q-icon :name="icon"/>
                    </template>
                    <template v-slot:append v-if="required">
                        <q-icon color="red" size="15px" name="mdi-asterisk"/>
                    </template>
                </q-input>
            </template>
        </template>
        <template v-else>
            <q-select
                v-model="model"
                :options="opcoes"
                :rules="((required || false) ? [
                    val => val !== null || 'Campo Obrigatório',
                ] : []).concat(rules || [])"
                :error="error || false"
                :error-message="errorMessage || ''"
                :hint="dica"
                :loading="loading || false"
                :autocomplete="autocomplete || ''"
                class="q-mx-md q-mt-md"
                option-label="label"
                option-value="value"
                map-options
                emit-value
                dense
                @change="emit('change')"
                :readonly="readonly"
                :label="label || ''"
                style="flex: 1;"
            >
                <template v-slot:prepend>
                    <q-icon :name="icon"/>
                </template>
                <template v-slot:append>
                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                </template>
            </q-select>
        </template>
    </form-col>
</template>
