<script setup>
import { defineProps, onMounted, ref, computed } from 'vue';
import useUtils from '../Composables/useUtils';
import { useQuasar } from 'quasar';
import ListaLotes from '../Componentes/Home/ListaLotes';
import useRotas from '../Composables/useRotas';
import Habilitacao from '../Componentes/Leilao/Habilitacao';
import useLeilao from '../Composables/useLeilao';
import useGlobals from '../Composables/useGlobals';
import useListagemLotesInfinita from '../Composables/useListagemLotesInfinita';
import useLeilaoLocalComUpdates from '../Composables/useLeilaoLocalComUpdates';
import LeilaoLayout from '../Layouts/LeilaoLayout';
import {usePage} from "@inertiajs/inertia-vue3";

const props = defineProps({
    leilaoInit: Object,
    habilitado: Boolean,
    habilitacoesLeilao: Array,
    idsHabilitacoesClienteAprovadas: Array,
    idsHabilitacoesClienteReprovadas: Array,
    idsHabilitacoesClienteVerificando: Array,
    idsHabilitacoesCliente: Array,
    statusHabilitacaoLeilao: Object,
    documentosRequeridos: Array,
    lotesIndice: Array
})

const dominio_venda_direta_ativo = computed(()=> usePage().props.value.dominio_venda_direta_ativo);

const {
    leilao
} = useLeilaoLocalComUpdates(props);

const $q = useQuasar();

const {
    getImagem,
    formatDate,
    formatador
} = useUtils();

const {
    baixar
} = useUtils();


const route = useRotas();

const {
    slide,
    timer,
    regulamento,
    catalogo,
    edital,
    outrosDocumentos,
    verDocumentos
} = useLeilao(leilao);

const {
    logoLeiloeiro,
    urlAtual,
    logado,
    emAnalise
} = useGlobals();

const habilitandoLocal = ref(false);

const {
    infiniteScroll,
    tudoCarregado,
    lotesCarregados,
    loadData
} = useListagemLotesInfinita(leilao);

const mostrarModal = ref(false);

const urlVideo = computed(() => {

    return `${leilao.value.link_transmissao}?rel=0&autoplay=1&loop=1`;

});

const isAvisoVisible = ref(false);

onMounted(() => {

    console.log(leilao?.value);

    if (!localStorage.getItem('aviso_visualizado.leilao.' +leilao.value.id)) {
        isAvisoVisible.value = true;
    }

    if (!localStorage.getItem('modal.' + leilao.value.id)){
        mostrarModal.value = true;
        localStorage.setItem('modal.' + leilao.value.id, 1);
    }
})


const defineAvisoInvisible =  () => {
    isAvisoVisible.value = false;
    localStorage.setItem('aviso_visualizado.leilao.' +leilao.value.id , 1);
}

const abrirLinkAviso =  () => {

    var linkAviso = leilao?.value.imagem_aviso_leilao?.link;

    if(+leilao.value.banner_aviso === 1 && linkAviso){

        if (!linkAviso.startsWith('http://') && !linkAviso.startsWith('https://')) {
            linkAviso = 'https://' + linkAviso;
        }

        defineAvisoInvisible();
        window.open(linkAviso, '_blank');
    }

}
</script>

<template>
    <LeilaoLayout :title="leilao.titulo" :description="leilao.descricao">
        <q-page>
            <q-dialog v-if="+leilao.id === 43 || +leilao.id === 156" v-model="mostrarModal" style="overflow: hidden;" :style="$q.screen.gt.sm ? 'width: 50vw' : 'width: 90vw'" >
                <q-card>
                    <q-card-section class="row items-center q-pb-none">
                        <div class="text-h6">Aviso</div>
                        <q-space />
                        <q-btn icon="mdi-close" flat round dense v-close-popup />
                    </q-card-section>

                    <q-card-section class="q-pa-none flex row justify-content-center">
                        <img src="/modals/dcti.jpeg" class="full-width " />
                    </q-card-section>
                </q-card>
            </q-dialog>
            <div class="flex row full-width">
                <div class="flex column col-12 col-md-6">
                    <q-responsive class="full-width" :ratio="16/9">
                        <div :style="
                            `position: absolute;
                            top: 0px;
                            width: 100%!important;
                            height: 40px!important;
                            z-index: 998;
                            background-color: ${leilao.status_leilao.cor_site};`
                        ">
                            <p style="color: white; font-size: 25px;font-weight: 300;text-align: center;">{{leilao.status_leilao.nome}}</p>
                        </div>
                        <q-carousel
                            v-model="slide"
                            animated
                            arrows
                            infinite
                            control-color="white"
                            control-text-color="primary"
                            control-type="push"
                            next-icon="mdi-chevron-right"
                            prev-icon="mdi-chevron-left"
                            navigation
                            style="height: 27em;"
                        >
                            <q-carousel-slide
                                v-if="+leilao.transmissao_iniciada === 1"
                                :name="1"
                                class="q-pa-none"
                            >

                                <q-video
                                    class="absolute-full"
                                    :src="urlVideo"
                                />
                                <q-carousel-control>
                                    <q-chip color="primary" text-color="white">
                                       AO VIVO
                                    </q-chip>
                                </q-carousel-control>
                            </q-carousel-slide>
                            <q-carousel-slide
                                v-for="(imagem, key) in (leilao?.imagens_leilao && leilao?.imagens_leilao.length > 0                ? leilao.imagens_leilao
                : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))"
                                :key="key"
                                :name="((+leilao.transmissao_iniciada === 1)?2:1)+key"
                                class="q-pa-none">
                                <q-img
                                    class="full-width full-height"
                                    loading="lazy"
                                    :src="getImagem(imagem?.arquivo, true, leilao.status_leilao?.nome)"
                                    :ratio="16/9"
                                >
                                    <template v-slot:error>
                                        <div class="absolute-full flex flex-center bg-primary text-white">
                                            Houve um problema carregando a imagem
                                        </div>
                                    </template>
                                    <template v-slot:loading>
                                        <div class="absolute-full flex column flex-center bg-white text-primary">
                                            <img :src="logoLeiloeiro" style="width: 40%" />
                                            <p class="q-mt-sm">
                                                <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                            </p>
                                        </div>
                                    </template>
                                </q-img>
                                <q-carousel-control v-if="leilao.proximoStatus.timestamp">
                                    <q-chip :color="leilao.proximoStatus.cor" text-color="white">
                                        {{leilao.proximoStatus.texto}} {{timer}}
                                    </q-chip>
                                </q-carousel-control>
                            </q-carousel-slide>
                        </q-carousel>
                    </q-responsive>
                    <div v-if="$q.screen.gt.sm" class="flex row justify-around q-ma-sm full-width">
                        <div class="row justify-evenly q-pt-xs full-width">
                            <q-card-section v-if="edital"
                                            @click="baixar(edital.arquivo.signedUrl)"
                                            class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-download"
                                    size="2.5em"
                                    style="flex: 1"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Edital
                                </h6>
                            </q-card-section>
                            <q-separator v-if="edital" inset vertical/>
                            <q-card-section v-if="regulamento"
                                            @click="baixar(regulamento.arquivo.signedUrl)"
                                            class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-download"
                                    size="2.5em"
                                    style="flex: 1"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Regulamento
                                </h6>
                            </q-card-section>
                            <q-separator v-if="regulamento" inset vertical/>
                            <q-card-section v-if="catalogo"
                                            @click="baixar(catalogo.arquivo.signedUrl)"
                                            class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-download"
                                    size="2.5em"
                                    style="flex: 1"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Catálogo
                                </h6>
                            </q-card-section>
                            <q-separator v-if="catalogo" inset vertical/>
                            <q-card-section v-if="outrosDocumentos"
                                            @click="verDocumentos = true"
                                            class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-file-document-multiple"
                                    size="2.5em"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Todos
                                </h6>
                            </q-card-section>
                        </div>
                    </div>
                </div>
                <div class="flex column col-12 col-md-6">
                    <div class="flex row items-center q-px-md q-pt-md q-pb-none">
                        <span class="text-primary q-pr-sm">
                            Em {{formatDate(leilao.data_hora_inicio)}}
                        </span>
                        <span class="text-primary q-px-sm"
                              style="border: 1px solid #1E4A7D; border-radius: 5px">
                         {{leilao.localidade?.nome || 'ONLINE'}}
                        </span>
                        <q-img
                            :src="leilao?.comitente?.arquivo?.logoComitenteUrl"
                            height="35px"
                            width="100px"
                            fit="contain">
                        </q-img>
                    </div>
                    <div class="flex column q-px-md q-pt-none">
                        <h4 class="q-ma-none text-primary">
                            {{ leilao.titulo }}
                        </h4>
                        <div class="q-mb-none" v-html="leilao.descricao"></div>
                    </div>
                    <div class="q-ma-sm">
                        <q-list>
                            <q-item v-if="+leilao?.venda_direta === 0" :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'" clickable>
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-gavel"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label>Leiloeira(o)</q-item-label>
                                    <q-item-label caption>
                                        {{ leilao?.leiloeiro?.nome }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="lotesCarregados[0] && +leilao?.venda_direta === 0" :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'" clickable>
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" :name="[
                                            'mdi-package-variant-closed',
                                            'mdi-home',
                                            'mdi-car'
                                        ][lotesCarregados[0].tipo_lote]"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label>Categoria do Leilão</q-item-label>
                                    <q-item-label caption>{{ [
                                            'Bens Diversos',
                                            'Imobiliario',
                                            'Veiculos'
                                        ][lotesCarregados[0].tipo_lote] }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                                clickable
                            >
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-calendar"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                        <template v-if="+leilao?.venda_direta === 1">
                                            Data Fechamento
                                        </template>
                                        <template v-else>
                                            Data<template v-if="leilao.data_hora_publicacao">s</template> do <template v-if="leilao.dois_leiloes === '1'">1º</template> Leilão
                                        </template>
                                    </q-item-label>
                                    <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" v-if="leilao.data_hora_publicacao" caption>Abertura: {{ formatDate(leilao.data_hora_publicacao) }}</q-item-label>
                                    <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>Fechamento: {{ formatDate(leilao.data_hora_inicio) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-if="leilao.lotes_count == 1 && +leilao?.venda_direta === 1"
                                clickable
                            >
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-currency-usd"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            Valor de Referência
                                    </q-item-label>
                                    <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                        Referência Inicial {{ formatador.format(leilao.lanceInicialPrimeiroLote) }}
                                    </q-item-label>

                                </q-item-section>
                            </q-item>
                            <q-item
                                v-if="leilao.lotes_count == 1 && +leilao?.venda_direta === 0"
                                clickable
                            >
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-currency-usd"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            Valores do <template v-if="leilao.dois_leiloes === '1'">1º</template> Leilão
                                    </q-item-label>
                                    <q-item-label :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" v-if="(+leilao.lanceAtualPrimeiroLote > 0) && (+leilao.primeiro_leilao_concluido !== 1)" caption>
                                        Lance Atual {{ formatador.format(leilao.lanceAtualPrimeiroLote) }}
                                    </q-item-label>
                                    <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" v-else caption>
                                        Lance Inicial {{ formatador.format(leilao.lanceInicialPrimeiroLote) }}
                                    </q-item-label>

                                    <q-item-label :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                        Incremento {{ formatador.format(leilao.incrementoPrimeiroLote) }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-else-if="lotesCarregados[0] && +leilao?.venda_direta === 0"
                                clickable
                            >
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-currency-usd"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label>Leilão com multiplos Lotes</q-item-label>
                                    <q-item-label caption>
                                        Veja os valores abaixo
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                                clickable
                                v-if="leilao.dois_leiloes === '1'"
                            >
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-calendar"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">Data<template v-if="leilao.data_hora_publicacao_segundo_leilao">s</template> do 2º Leilão</q-item-label>
                                    <q-item-label :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" v-if="leilao.data_hora_publicacao_segundo_leilao" caption>Abertura: {{ formatDate(leilao.data_hora_publicacao_segundo_leilao) }}</q-item-label>
                                    <q-item-label :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>Fechamento: {{ formatDate(leilao.data_hora_inicio_segundo_leilao) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-if="leilao.lotes_count == 1 && leilao.dois_leiloes === '1'"
                                clickable
                            >
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-currency-usd"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" >Valores do 2º Leilão</q-item-label>
                                    <q-item-label :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" v-if="(+leilao.lanceAtualPrimeiroLote > 0) && (+leilao.primeiro_leilao_concluido === 1)" caption>
                                        Lance Atual {{ formatador.format(leilao.lanceAtualPrimeiroLote) }}
                                    </q-item-label>
                                    <q-item-label  :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" v-else caption>
                                        Lance Inicial {{ formatador.format(leilao.lanceInicialSegLeilaoPrimeiroLote) }}
                                    </q-item-label>
                                    <q-item-label :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                        Incremento {{ formatador.format(leilao.incrementoSegLeilaoPrimeiroLote) }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-else-if="leilao.dois_leiloes === '1' && lotesCarregados[0]"
                                clickable
                            >
                                <q-item-section avatar class="items-center">
                                    <q-icon color="positive" name="mdi-currency-usd"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label>Leilão com multiplos Lotes</q-item-label>
                                    <q-item-label caption>
                                        Veja os valores abaixo
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item clickable>
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-package-variant"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-tooltip>
                                        Leilão aberto com {{ leilao.lotes_count }} lotes,
                                        {{leilao.lotes_vendidos}} já foram vendidos,
                                        {{leilao.lotes_cancelados}} cancelado(s),
                                        {{leilao.lotes_retirados}} retirado(s)
                                    </q-tooltip>
                                    <q-item-label>{{ leilao.lotes_count }} Lotes</q-item-label>
                                    <q-item-label caption>
                                        {{leilao.lotes_vendidos}} Lotes Vendidos
                                    </q-item-label>
                                </q-item-section>

                            </q-item>

                            <q-item clickable v-if="+leilao?.venda_direta === 1 && 1 === 2">
                                <q-item-section avatar class="items-center">
                                    <q-icon color="primary" name="mdi-alert-circle-check"/>
                                </q-item-section>

                                <q-item-section>
                                    <q-tooltip>
                                        Regras de Participação
                                    </q-tooltip>
                                    <q-item-label overline>
                                        Regras de Participação
                                    </q-item-label>
                                    <q-item-label >
                                        - Somente empresas que possuem o CNAE 45.11 - Comércio a varejo e por atacado de veículos automotores poderão enviar propostas.<br/>
                                        - Somente lojistas selecionados pelo time comercial poderão participar dos eventos.<br/>
                                        - O participante deve estar com a situação regular na Receita Federal.
                                    </q-item-label>
                                </q-item-section>
                            </q-item>

                        </q-list>
                    </div>
                    <div class="flex column q-mx-md" v-if="lotesCarregados[0] && +leilao?.venda_direta === 0">
                        <q-btn
                            v-if="!logado"
                            @click="$inertia.visit(route('auth.login', {
                                _query: {
                                    intended: urlAtual
                                }
                            }))"
                            :class="'bg-primary'"
                            class="text-white full-width hide-print"
                            label="Entre para dar seu lance!"
                        />
                        <q-btn
                            v-else-if="emAnalise"
                            disable
                            :class="'bg-warning'"
                            class="text-white full-width hide-print"
                            label="O seu cadastro está em analise"
                        />
                        <q-btn
                            v-else-if="!habilitado"
                            @click="habilitandoLocal = true"
                            :class="'bg-primary'"
                            class="text-white full-width hide-print"
                            label="Habilite-se para dar seu lance!"
                        />

                        <q-btn
                            v-else-if="leilao.status_leilao.identificador === 'ABERTO'"
                            :class="'bg-positive'"
                            class="text-white full-width hide-print"
                            disable
                            label="Acesse os lotes abaixo"
                        />
                        <q-btn
                            v-if="logado"
                            :class="'bg-positive q-mt-sm'"
                            @click="$inertia.visit(route('auditorio.lote', {idLeilao: leilao.id}))"
                            class="text-white full-width hide-print"
                            label="Acessar Auditório"
                        />
                    </div>
                </div>
                <div v-if="$q.screen.lt.md && +leilao?.venda_direta === 0" class="flex row justify-around q-ma-sm full-width">
                    <div class="row justify-evenly q-pt-xs full-width">
                        <q-card-section v-if="edital"
                                        @click="baixar(edital.arquivo.signedUrl)"
                                        class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-download"
                                size="2.5em"
                                style="flex: 1"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Edital
                            </h6>
                        </q-card-section>
                        <q-separator v-if="edital" inset vertical/>
                        <q-card-section v-if="regulamento"
                                        @click="baixar(regulamento.arquivo.signedUrl)"
                                        class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-download"
                                size="2.5em"
                                style="flex: 1"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Regulamento
                            </h6>
                        </q-card-section>
                        <q-separator v-if="regulamento" inset vertical/>
                        <q-card-section v-if="catalogo"
                                        @click="baixar(catalogo.arquivo.signedUrl)"
                                        class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-download"
                                size="2.5em"
                                style="flex: 1"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Catálogo
                            </h6>
                        </q-card-section>
                        <q-separator v-if="catalogo" inset vertical/>
                        <q-card-section v-if="outrosDocumentos"
                                        @click="verDocumentos = true"
                                        class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-file-document-multiple"
                                size="2.5em"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Todos
                            </h6>
                        </q-card-section>
                    </div>
                </div>
            </div>
            <div class="flex row full-width">
                <h2 class="full-width text-center q-mt-sm text-h4 q-px-md">
                    <template v-if="+leilao?.venda_direta === 1">
                        Lotes do Auto Sato -
                    </template>
                    <template v-else>
                        Lotes do Leilão -
                    </template>
                    {{ leilao.titulo }}

                </h2>
            </div>
            <div class="flex row full-width">
                <q-expansion-item class="text-center full-width bg-grey-2 q-mx-sm" label="Condições de Venda">
                    <q-card class="q-pa-sm" bordered>
                        <h6 class="no-margin no-padding text-center">Condições de Venda</h6>
                        <div v-html="leilao.descricao_do_pagamento"></div>
                    </q-card>
                </q-expansion-item>
            </div>
            <div class="flex row full-width" id="#lotes">
                <q-infinite-scroll class="full-width" @load="loadData" :initial-index="0" :offset="850" ref="infiniteScroll">
                    <lista-lotes :lotes="lotesCarregados" :leilao="leilao" />
                    <template v-slot:loading>
                        <div class="full-width row justify-center q-my-md">
                            <div class="column justify-center items-center content-center">
                                <q-spinner-dots color="primary" size="60px" />
                                <p class="text-primary">Carregando mais lotes...</p>
                            </div>
                        </div>
                    </template>
                </q-infinite-scroll>
            </div>
            <template v-if="tudoCarregado && lotesCarregados.length === 0">
                <div class="full-width row justify-center q-my-md">
                    <div class="column justify-center items-center content-center">
                        <p class="text-primary">Ainda não tem nenhum lote registrado nesse leilão.</p>
                    </div>
                </div>
            </template>
            <template v-else-if="tudoCarregado">
                <div class="full-width row justify-center q-my-md">
                    <div class="column justify-center items-center content-center">
                        <p class="text-primary">
                            {{ (dominio_venda_direta_ativo) ? 'Estes são todos os lotes para venda direta!' : 'Estes são todos os lotes neste leilão!' }}

                        </p>
                    </div>
                </div>
            </template>
            <habilitacao
                v-model="habilitandoLocal"
                :leilao="leilao"
                :habilitado="habilitado"
                :habilitacoesLeilao="habilitacoesLeilao"
                :idsHabilitacoesClienteAprovadas="idsHabilitacoesClienteAprovadas"
                :idsHabilitacoesClienteReprovadas="idsHabilitacoesClienteReprovadas"
                :idsHabilitacoesClienteVerificando="idsHabilitacoesClienteVerificando"
                :idsHabilitacoesCliente="idsHabilitacoesCliente"
                :statusHabilitacaoLeilao="statusHabilitacaoLeilao"
                :documentosRequeridos="documentosRequeridos"/>

            <q-dialog v-model="verDocumentos">
                <q-card class="flex no-wrap column" style="min-width: 30vw; overflow-y: auto;overflow-x: hidden">
                    <q-toolbar class="bg-primary text-white">
                        <q-icon name="mdi-gavel" />
                        <q-toolbar-title>Todos os documentos</q-toolbar-title>
                        <q-btn flat round color="white" icon="mdi-window-close" @click="verDocumentos = false"/>
                    </q-toolbar>
                    <q-list style="max-height: 65vh; overflow-y: auto;">

                        <q-item  v-if="outrosDocumentos.length === 0 ">
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" size="lg" name="mdi-close-thick"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label overline>Sem anexos</q-item-label>
                                <q-item-label color="primary" class="text-h5">
                                    Nenhum documento encontrado
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item v-for="(documento, idx) in outrosDocumentos" :key="idx"
                                clickable
                                @click="baixar(documento.arquivo.signedUrl)">
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" size="lg" name="mdi-download"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label overline>{{ {'0': 'Regulamento', '1': 'Catálogo', '2': 'Edital', '3': 'Outros'}[documento.tipo] }}</q-item-label>
                                <q-item-label color="primary" class="text-h5">
                                    {{ documento.descricao }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-card>
            </q-dialog>


            <q-dialog v-model="isAvisoVisible" @hide="defineAvisoInvisible" v-if="+leilao.banner_aviso === 1 && leilao?.imagem_aviso_leilao?.arquivo?.signedUrl">
                <q-card class="flex no-wrap column" style="overflow-y: auto;overflow-x: hidden" :style="$q.screen.gt.sm ? 'min-width: 45vw;' : 'min-width: 90vw;'">
                    <q-img
                            :src="leilao?.imagem_aviso_leilao?.arquivo?.signedUrl"
                            class="full-with"
                            :class="(leilao?.imagem_aviso_leilao?.link) ? 'cursor-pointer' : ''"
                            @click="abrirLinkAviso"
                        >
                            <q-toolbar class="bg-transparent">
                                <q-toolbar-title></q-toolbar-title>
                                <q-btn flat round color="white" class="bg-primary" icon="mdi-window-close" @click.stop="defineAvisoInvisible" />
                            </q-toolbar>
                        </q-img>
                </q-card>

            </q-dialog>

        </q-page>
    </LeilaoLayout>
</template>
<style>
 .no-margins .q-dialog__message{
     padding: 0;
 }
</style>
