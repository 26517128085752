<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../Layouts/BlankLayout';
import Icone from '../../Componentes/Global/Icone';
import { useQuasar } from 'quasar';

const $q = useQuasar();
</script>

<template>
    <Head title="Solicitação Registrada" />
    <BlankLayout>
        <q-page class="flex column justify-center items-center content-center">
            <icone height="7em" width="14em" class="q-mb-md" />
            <h3
                class="text-center text-primary font-weight-light no-margin q-ma-md"
                :class="$q.screen.gt.sm ? 'text-h3' : 'text-h5'">
                Redefinição de Segurança
            </h3>
            <p  style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md q-mt-md">
                Por medida de segurança, precisamos definir para você uma nova senha.
            </p>
            <p  style="max-width: 70vw" class="text-center text-h5 font-weight-light no-margin q-ma-md q-pb-md">
                Enviamos um email com um link de redefinição no seu email cadastrado.
            </p>
            <small class="text-primary">Caso não veja o email em sua caixa de entrada, verifique sua caixa de spam/lixo eletrônico</small>
        </q-page>
    </BlankLayout>
</template>
