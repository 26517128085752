import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed, watch } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';
import { camelCase, startCase } from 'lodash';

export function useDialogos() {
    const $q = useQuasar();
    const errors = computed(() => usePage().props.value.errors)
    const notify = computed(() => usePage().props.value.notify)
    const dialog = computed(() => usePage().props.value.dialog)

    watch(errors, () => {
        if (typeof errors.value === 'object'){
            Object.entries(errors.value).forEach(([key, message]) => {
                $q.notify({
                    type: 'negative',
                    icon: 'mdi-alert-circle',
                    message: `${startCase(camelCase(key))}: ${message}`
                })
            })
        }
    })

    watch(notify, () => {
        if (notify.value.mensagem){
            $q.notify({
                type: notify.value.cor,
                icon: notify.value.icone,
                message: notify.value.mensagem
            })
        }
    })

    watch(dialog, () => {
        if (dialog.value.mensagem){
            $q.dialog({
                color: dialog.value.cor,
                title: dialog.value.titulo,
                message: dialog.value.mensagem
            })
        }
    })

}
