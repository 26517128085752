import script from "./Home.vue?vue&type=script&setup=true&lang=js"
export * from "./Home.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCarousel,QCarouselSlide,QSeparator,QBreadcrumbs,QBreadcrumbsEl,QDialog,QCard,QToolbar,QToolbarTitle,QIcon,QBtn,QPageSticky,QImg,QInfiniteScroll,QSpinnerDots});
