<script setup>
import { defineProps, ref } from 'vue';
import { Link } from '@inertiajs/inertia-vue3'

defineProps({
    texto: String,
    href: String
})
const currentLocation = ref(location.href.replace(/\/$/, ""));
</script>

<template>
    <Link
        as="div"
        :href="href"
        :class="{ 'active': currentLocation === href}"
        class="flex row justify-start link-topo">
        {{ texto }}
    </Link>
</template>

<style type="text/sass" scoped>
.link-topo{
    padding: 5px 15px;
    cursor: pointer;
    transition: 0.3s;
}
.link-topo:hover{
    background-color: rgba(255, 255, 255, 0.10);
}
.link-topo.active{
    background-color: rgba(255, 255, 255, 0.10);
    color: var(--q-positive);
}
</style>
