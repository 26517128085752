<script setup>
import { ref, defineProps } from 'vue';
import { Link } from '@inertiajs/inertia-vue3'
const logoUrl = ref(window.doulheTresConfig.logo_url);

defineProps({
    height: String,
    width: String,
});

</script>

<template>
    <Link href="/">
        <q-img :height="height || '40px'" :width="width || '80px'" :src="logoUrl" />
    </Link>
</template>
